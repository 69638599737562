export default {
    "administration": "Amministrazione",
    "admin_page": {
        "no_permission": "Non ha il permesso di accedere all'area di amministrazione.",
        "confirm_before_delete_msg": "Vuole davvero rimuovere questa voce? (ID #{id})",
        "confirm_before_delete_from_db_msg": "Vuole davvero eliminare questa voce? (ID #{id})",
        "confirm_before_delete_from_db_title": "Cancellare in modo permanente",
        "confirm_before_delete_from_db_btn": "Cancellare",
        "block_user_msg": "Vuole davvero bloccare {name}? La persona non potrà quindi accedere.",
        "block_user_title": "Blocco di accesso",
        "block_user_btn": "Blocco",
        "unblock_user_msg": "Vuole davvero sbloccare {name}? La persona potrà quindi accedere di nuovo.",
        "unblock_user_title": "Sbloccare il login",
        "unblock_user_btn": "Sbloccare",
        "tour": {
            "menu_title": "Sezioni",
            "menu": "Qui può vedere le sezioni dell'area di amministrazione dove può gestire e modificare i dati anagrafici e le impostazioni.",
            "client_menu_title": "Gestire le aziende",
            "client_menu": "Qui può aggiungere, visualizzare o modificare tutte le aziende.",
            "client_list": "Qui può vedere tutte le aziende.",
            "new_client": "Clicchi qui per creare una nuova azienda.",
            "view_mode": "<p> Qui può passare dalla vista elenco alla vista tabella per impostare più voci contemporaneamente o per avere una migliore panoramica.</p> La vista tabella può essere utilizzata anche per l'esportazione o l'importazione di dati. Non dimentichi di salvare la tabella dopo la modifica."
        }
    },
    "version": "Versione",
    "yes": "Sì",
    "no": "No",
    "never": "Mai",
    "add": "Aggiungi",
    "edit": "Modifica",
    "delete": "Cancellare",
    "save": "Salva",
    "cancel": "Annulla",
    "next": "Avanti",
    "back": "Indietro",
    "finish": "Finitura",
    "ok": "Ok",
    "open": "Aperto",
    "dashboard": "Dashboard",
    "overview": "Panoramica",
    "operator": "Operator",
    "profile": "Profilo",
    "help": "Aiuto",
    "logout": "Esci",
    "list_view": "Visualizza elenco",
    "table_view": "Visualizza tabella",
    "archive": "Archivio",
    "active": "Attivo",
    "email": "Email",
    "name": "Nome",
    "tax": "Imposte (%)",
    "value": "Importo",
    "number": "Numero",
    "currency": "Valuta",
    "actions": "Azioni",
    "system": "Sistema",
    "from": "Da",
    "of": "Di",
    "until": "fino a quando",
    "up_to": "fino a",
    "cost_center_id": "Centro di costo",
    "cost_centers": "Centri di costo",
    "budget_id": "Budget",
    "budgets": "Budget",
    "branch_id": "Filiale",
    "branches": "Filiali",
    "employee_id": "Dipendente",
    "employees": "Dipendenti",
    "d365_vehicle_id": "Veicolo",
    "vehicles": "Veicoli",
    "financial_dimension_id": "Dimensione finanziaria",
    "financial_dimensions": "Dimensioni finanziaria",
    "tax_group_id": "Gruppo imposta sulle vendite",
    "tax_groups": "Gruppi imposta sulle vendite",
    "tax_item_group_id": "Gruppo articoli imposta sulle vendite",
    "tax_item_groups": "Gruppi articoli imposta sulle vendite",
    "profit_center_id": "Centro di profitto",
    "profit_centers": "Centri di profitto",
    "client_id": "Azienda",
    "clients": "Aziende",
    "department_id": "Reparto",
    "departments": "Reparti",
    "d365_department_id": "D365 Reparto",
    "d365_departments": "D365 Reparti",
    "location_id": "Posizione",
    "consignee_id": "Ricevimento della merce",
    "address_id": "Indirizzo di consegna",
    "lb_user_id": "Persona",
    "users": "Persone",
    "task_group_id": "Gruppo di compiti",
    "task_groups": "Gruppi di compiti",
    "user_task_group": "Utente/Gruppo di attività",
    "user_task_groups": "Utenti/Gruppi di attività",
    "product": "Prodotto",
    "products": "Catalogo dei prodotti",
    "product_group_id": "Gruppo di prodotti",
    "product_groups": "Gruppi di prodotti",
    "supplier_id": "Fornitore",
    "suppliers": "Fornitori",
    "procurement_id": "Ordine di acquisto",
    "procurements": "Ordini di acquisto",
    "user_select_product_id": "Persona 'Selezione del prodotto'",
    "user_send_order_id": "Persona 'Ordinato da'",
    "user_clearing_id": "Persona 'Chiarimenti sull'acquisto'",
    "user_consignee_id": "Persona 'Ricevimento della merce'",
    "user_invoice_manual_check_id": "Persona 'Controllo manuale (fattura)'",
    "user_invoice_clearing_id": "Persona 'Chiarimenti contabile'",
    "user_accounting_id": "Utente 'Contabilità'",
    "ledger_account_id": "Conto del libro mastro",
    "ledger_accounts": "Conti del libro mastro",
    "posting_key_id": "Posting key",
    "posting_keys": "Posting keys",
    "permission_id": "Gruppo di autorizzazione",
    "permissions": "Gruppi di autorizzazione",
    "invoices": "Fatture",
    "invoice_id": "Fattura",
    "configurations": "Impostazioni",
    "marketplace_id": "Marketplace",
    "provider_id": "Fornitore del Marketplace",
    "notes": "Nota",
    "search": "Trova",
    "no_data": "Nessuna voce trovata",
    "months": "Mesi",
    "select_valid_option": "Selezioni una voce valida.",
    "procurement_line": {
        "description": "Prodotto",
        "item_number": "Numero di articolo",
        "is_cost_center_fixed": "Preset fisso",
        "image_url": "URL immagine",
        "webshop_url": "URL del negozio",
        "offer_number": "Numero dell'offerta",
        "offer_date": "Data dell'offerta",
        "include_tax": "incl. {tasse} IVA",
        "offer_msg": "In base all'offerta {numero_offerta}",
        "offer_date_msg": "su {data_offerta}",
        "total_price_brutto": "Totale lordo",
        "total_price_netto": "Totale netto",
        "type_total_price_brutto": "currency",
        "type_price_brutto": "currency",
        "type_total_price_netto": "currency",
        "type_price_netto": "currency"
    },
    "product_catalog": {
        "description": "Articolo",
        "add_product_btn": "Aggiungi prodotto",
        "item_number": "Numero di articolo",
        "is_cost_center_fixed": "Assegnazione fissa",
        "image_url": "URL immagine",
        "webshop_url": "URL del negozio",
        "offer_number": "Numero dell'offerta",
        "offer_date": "Data dell'offerta",
        "type_price_netto": "galleggiante",
        "template_price_netto": "$1 €",
        "type_price_brutto": "galleggiante",
        "template_price_brutto": "$1 €",
        "form_sequence": "name,item_number,product_group_id,supplier_id,location_id,cost_center_id,is_cost_center_fixed,tax,price_netto,price_brutto,unit,image_url,webshop_url,offer_number,offer_date",
        "page": {
            "new": "Nuovo prodotto",
            "supplier_tooltip": "Selezioni un fornitore dai suoi dati anagrafici o ne aggiunga uno nuovo digitando un nuovo nome.",
            "supplier_new_tooltip": "Questo fornitore sarà salvato nei suoi dati anagrafici quando aggiungerà il prodotto.",
            "should_save": "Salva nel catalogo prodotti",
            "validation": {
                "name_required": "La preghiamo di inserire un nome.",
                "supplier_required": "Selezioni un fornitore o ne aggiunga uno nuovo.",
                "image_url_invalid": "Inserisca un URL valido.",
                "webshop_url_invalid": "Inserisca un URL valido.",
                "tax_invalid": "Inserisca un'aliquota fiscale valida."
            },
            "tour": {
                "name": "Fornisca un nome significativo per il prodotto.",
                "number": "Qui può inserire il numero di articolo del prodotto.",
                "product_group": "Scelga il gruppo di prodotti a cui appartiene il prodotto.",
                "supplier": "Scelga il fornitore del prodotto da cui ordinarlo.",
                "price": "Specifichi un'aliquota fiscale e un prezzo netto o lordo. L'altro prezzo sarà calcolato utilizzando l'aliquota fiscale.",
                "unit": "Indicare l'unità di misura del prodotto, ad esempio \"articoli\", \"kg\" o \"litri\".",
                "url": "Qui può aggiungere un'immagine URL al prodotto. Inoltre, se disponibile, può aggiungere un link alla pagina del prodotto nel negozio.",
                "offer": "Se esiste un'offerta per il prodotto, può inserire qui il numero e la data dell'offerta per visualizzarli sul PDF dell'ordine.",
                "btn_save": "Salvi il prodotto nel catalogo prodotti una volta aggiunte tutte le informazioni."
            }
        }
    },
    "product_group": {
        "form_sequence": "name,description,user_select_product_id",
        "page": {
            "new": "Nuovo gruppo di prodotti",
            "no_product_group": "Senza gruppo di articoli",
            "validation": {
                "name_required": "La preghiamo di inserire un nome."
            },
            "tour": {
                "name": "Fornisca un nome significativo per il gruppo di prodotti.",
                "btn_save": "Una volta fornite tutte le informazioni, aggiunga il gruppo di prodotti."
            }
        }
    },
    "procurement": {
        "description": "Ordine di acquisto",
        "order_description": "Ordine",
        "name": "Nome",
        "number": "Numero d'ordine",
        "order_number_formatted": "Numero d'ordine",
        "creator_id": "Creato da",
        "created_at": "Data di creazione",
        "delivery_date": "Data di consegna",
        "delivery_address_id": "Indirizzo di consegna",
        "billing_address_text": "Indirizzo di fatturazione",
        "status": "Stato",
        "total_brutto": "Totale lordo",
        "total_netto": "Totale netto",
        "total_netto_abbr": "-Rete",
        "payment_in_advance": "Pagamento anticipato",
        "type": "Tipo di ordine",
        "type_options": {
            "single_invoice": "eKuez con fattura singola",
            "recurring_invoice": "dKuez con fattura singola",
            "recurring_contract": "dKuez con fattura ricorrente"
        },
        "statuses": {
            "open": "Selezione dei prodotti",
            "approved": "Ordine di acquisto",
            "ordered": "In attesa della consegna",
            "declined": "Rifiutato",
            "completed": "Completato",
            "approval": "Approvato",
            "clearing": "Chiarimenti sull'acquisto",
            "draft": "Bozza"
        },
        "page": {
            "no_delivery_address": "Nessun indirizzo di consegna",
            "no_budget": "Nessun budget",
            "new": "Nuovo ordine di acquisto",
            "create_btn": "Avvio dell'ordine di acquisto",
            "copy_btn_tooltip": "Copia dell'ordine di acquisto",
            "delivery_date_tooltip": "La data di consegna sarà stampata sul PDF dell'ordine e potrà essere inviata al fornitore.",
            "order_sender": "Ordine di acquisto",
            "product_selector": "Selezione dei prodotti",
            "product_search": "Trova prodotti",
            "before_leave_msg": "Il suo ordine di acquisto non è stato salvato. È sicuro di voler lasciare la pagina?",
            "confirm_without_approval_limit_msg": "Nessuna delle persone selezionate ha il limite di approvazione necessario per il valore di questo ordine. È possibile aggiungere ulteriori approvazioni in un secondo momento. È sicuro di voler procedere?",
            "confirm_without_approval_limit_title": "Limite di approvazione",
            "confirm_product_selection_without_product_msg": "Non ha selezionato alcun prodotto. È sicuro di voler procedere?",
            "confirm_product_selection_without_product_title": "Nessun prodotto selezionato",
            "no_approval_limit_msg": "Il suo limite di approvazione non è sufficiente per questo ordine. La preghiamo di aggiungere un'altra persona con un limite di approvazione adeguato per l'approvazione.",
            "no_approval_limit_title": "Limite di approvazione",
            "product_group_missing_msg": "Tutti i prodotti devono essere assegnati ad un gruppo di prodotti. Vada alla scheda 'Gruppi di prodotti' sopra l'elenco dei prodotti e assegni un gruppo di prodotti a ciascun prodotto.",
            "product_group_missing_title": "Assegnazione del gruppo di prodotti",
            "product_group_missing_btn": "Capito",
            "min_approval_one_person": "1 persona",
            "min_approval_qty": "{qty} persone diverse",
            "min_approval_error_msg": "L'approvazione non è sufficiente per questo ordine d'acquisto. Aggiunga un'altra persona per l'approvazione. Questo ordine di acquisto deve essere approvato da <b>almeno {min_approval_msg}</b>.",
            "min_approval_error_title": "Approvazione insufficiente",
            "order_not_completed_msg": "Non ha ordinato tutti i prodotti o non li ha confermati. Si assicuri di aver ordinato tutti i prodotti dell'elenco e li confermi, se necessario, per procedere.",
            "order_not_completed_title": "Completi prima gli ordini",
            "order_not_completed_btn": "Capito",
            "confirm_delivery_not_completed_msg": "Non ha confermato tutte le ricevute della merce. È sicuro di voler completare l'ordine di acquisto? <br> <i>Questa azione non può essere annullata</i>.",
            "confirm_delivery_not_completed_title": "Ricevute in sospeso di merci",
            "confirm_delivery_not_completed_btn": "Completa",
            "default_confirm_btn": "Continua",
            "budget_exceeded_msg": "Budget superato",
            "approval_limit_exceeded_msg": "Limite di approvazione superato",
            "external_order_number_msg": "Inserisca il numero d'ordine esterno.",
            "external_order_number_title": "Inserisca il numero d'ordine esterno",
            "no_edit_permission": "Non ha l'autorizzazione a modificare i dati. La preghiamo di inviare l'ordine di acquisto per chiarimenti tramite azioni per apportare modifiche ai dati.",
            "delivery_dialog": {
                "title": "Confermare la ricezione della merce",
                "confirm_btn": "Confermare",
                "message": "Inserisca la quantità ricevuta di recente.<br> {quantità_mancanti} di {quantità} {unità} sono mancanti.",
                "qty_exceeded_msg": "La quantità mancante non deve essere superata.",
                "qty_must_be_greater_msg": "La quantità deve essere maggiore di 0.",
                "invalid_format": "Utilizzi un formato numerico valido."
            },
            "order_sender_dialog": {
                "title": "Ruolo predefinito per l'inserimento degli ordini",
                "info": "La persona nella fase 'Inserimento dell'ordine' viene determinata in base ai ruoli predefiniti per il reparto, l'azienda, i ruoli predefiniti globali e la persona che ha creato l'ordine di acquisto. La persona in cima all'elenco è preselezionata per il compito."
            },
            "consignee_dialog": {
                "title": "Ruolo predefinito per il ricevimento della merce",
                "info": "La persona nella fase 'Ricevimento merci' viene determinata in base ai ruoli predefiniti per il reparto, la società, i ruoli globali predefiniti e la persona che ha creato l'ordine di acquisto. La persona in cima all'elenco è preselezionata per il compito."
            },
            "select_product_dialog": {
                "title": "Ruolo predefinito per la selezione dei prodotti",
                "info": "La persona nella fase 'Selezione del prodotto' viene determinata in base ai ruoli predefiniti per il reparto, l'azienda, i ruoli predefiniti globali e la persona che ha creato l'ordine di acquisto. La persona in cima all'elenco è preselezionata per il compito."
            },
            "marketplace_change_dialog": {
                "title": "L'ordine sarà annullato",
                "info": "È stato rilevato un cambio di azienda. Tuttavia, esiste un ordine nel marketplace {marketplace_name} che non è collegato alla nuova azienda {client_name}. Se procedi, questo ordine sarà annullato.",
                "btn_confirm": "Annulla ordine"
            },
            "dialog_assignment": {
                "department_form": "Reparto in forma",
                "department_creator": "Reparto della persona ordinata",
                "client_form": "Azienda in forma",
                "client_creator": "Azienda della persona ordinata",
                "no_selection": "Non ancora selezionato",
                "no_action": "Non applicabile",
                "configuration": "Ruolo globale predefinito"
            },
            "draft": {
                "title": "Bozza",
                "save": "Salvare la bozza",
                "delete": "Cancellare la bozza",
                "confirm_before_delete_msg": "È sicuro di voler eliminare questa bozza in modo permanente?",
                "confirm_before_delete_title": "Cancellare la bozza",
                "confirm_before_delete_btn": "Cancellare"
            },
            "punchout": {
                "pending_msg": "Ci sono prodotti in sospeso da Punchout.",
                "error_title": "Errore di perforazione",
                "error_msg": "Si è verificato un errore. Controlli le impostazioni di Punch-out o contatti una persona con diritti di amministratore.",
                "error_start_url_msg": "Non è stato fornito un URL di avvio, non è possibile avviare il Punch-out.",
                "no_config": "La preghiamo di impostare prima la configurazione per utilizzare il Punch-out.",
                "pending_dialog": {
                    "title": "Prodotti in attesa da Punchout",
                    "add_products": "Aggiungi all'ordine",
                    "add_all_products": "Aggiungi tutti all'ordine",
                    "delete_products": "Rimuovi i prodotti"
                },
                "log_dialog": {
                    "title": "Richiesta/Risposta {supplier_name}",
                    "request": "Richiesta",
                    "response": "Risposta",
                    "no_log": "Nessun log",
                    "url": "Url",
                    "header": "Header",
                    "method": "Method",
                    "body": "Body"
                },
                "delete_dialog": {
                    "title": "Rimuova i prodotti da {fornitore} #{index}",
                    "info1": "Desidera davvero rimuovere tutti i seguenti prodotti da {fornitore} #{index}?",
                    "info2": "Questa azione non può essere annullata.",
                    "delete_btn": "Rimuovere"
                },
                "loading": "Completi la selezione del prodotto nel pop-up aperto. Se non appare alcun pop-up, deve prima autorizzarlo.",
                "can_not_edit": "I prodotti eliminati non possono essere modificati. Esegua nuovamente il Punch-out o cancelli l'intero carrello.",
                "no_provider_found": "Purtroppo, il fornitore di Punch-out selezionato non è ancora supportato."
            },
            "download_pdf": {
                "title": "Scarica PDF",
                "confirm_btn": "Conferma",
                "title_confirm_save_changes": "Salva dati non salvati",
                "text_confirm_save_changes": "Desideri salvare i dati non salvati e applicarli al documento d'acquisto?",
                "invalid_form": "Si prega di compilare tutti i campi obbligatori nel modulo prima di procedere con l'azione."
            },
            "lines": {
                "no_line": "Non sono stati selezionati prodotti, la preghiamo di aggiungere prodotti.",
                "show_all_info": "Vista avanzata",
                "invoice": {
                    "title": "Fattura",
                    "created_at": "Registrato",
                    "date": "Data della fattura",
                    "created_by": "Creato da"
                },
                "automatic_order": "Inviare elettronicamente",
                "automatic_ordered": "Inviato elettronicamente",
                "confirm_order": "Confermare l'ordine",
                "finish_order": "Ordine di finitura",
                "order_finished": "Ordine completato",
                "confirm_order_tooltip": "Si prega di notare che è necessario <b>inviare l'ordine al fornitore via e-mail</b>, l'ordine non verrà <b>trasmesso elettronicamente.</b>",
                "ordered_at": "Trasmesso",
                "ordered_by": "Da",
                "confirm_receipt": "Confermare la ricezione della merce",
                "no_cost_center": "Nessun Centro di costo",
                "include_taxes": "IVA inclusa",
                "budget": "Bilancio",
                "no_budget": "Nessun budget",
                "no_product_group": "Nessun gruppo di prodotti",
                "no_ledger_account": "Nessun conto contabile",
                "select_product": "Selezione dei prodotti",
                "quantity_to_show": "ricevuto: <b>{quantità_ricevuta} di {quantità}</b>",
                "from": "da",
                "item_number": "Codice articolo. #",
                "delete_msg": "È sicuro di voler rimuovere questo prodotto?",
                "delete_title": "Rimuovi il prodotto",
                "delete_btn": "Rimuovere",
                "automatic_order_dialog": {
                    "confirm_order": "Inviare l'ordine",
                    "confirm_order_btn": "Inviare",
                    "finish_order": "Ordine di finitura",
                    "finish_order_btn": "Finitura",
                    "info1": "Vuole <span style=\"font-weight: bold\">inviare elettronicamente</span> questo ordine al fornitore <span style=\"font-weight: bold\">ora</span>?",
                    "info2": "Questa azione non può essere annullata."
                },
                "order_dialog": {
                    "confirm_order": "Confermare l'ordine",
                    "confirm_order_btn": "Confermare",
                    "finish_order": "Ordine di finitura",
                    "finish_order_btn": "Finitura",
                    "info1": "Ha trasmesso l'ordine al fornitore (ad esempio, via e-mail o fax)?",
                    "info2": "Questa azione non può essere annullata."
                },
                "more_actions": {
                    "delete_products": "Rimuovi i prodotti",
                    "add_external_id": "Aggiungere il numero d'ordine esterno",
                    "download_pdf": "Ordine (PDF)",
                    "download_pdf_with_attachments": "Ordine incluso allegato PDF",
                    "write_email": "Comporre l'e-mail",
                    "email_with_pdf": "E-Mail con PDF"
                },
                "file": {
                    "open_btn": "Aprire il PDF",
                    "add_btn": "Aggiungi PDF",
                    "upload_info1": "Trascini qui <br>o <br>selezioni il file</em>.",
                    "upload_info2": "File PDF fino a 10MB.",
                    "delete_msg": "Vuole davvero rimuovere questo documento?",
                    "delete_title": "Rimuovi documento",
                    "delete_btn": "Rimuovere",
                    "delete_error_msg": "Non è stato possibile rimuovere il documento."
                }
            },
            "validation": {
                "delivery_date_format": "Inserisca una data di consegna.",
                "client_id_required": "Selezioni un'azienda.",
                "department_id_required": "Selezioni un reparto.",
                "department_id_required_client_missing": "Prima seleziona l'azienda.",
                "cost_center_id_required": "Selezioni un centro di costo.",
                "cost_center_id_required_filters_missing": "Prima seleziona l'azienda e il dipartimento.",
                "cost_center_id_required_department_missing": "Prima seleziona il dipartimento.",
                "cost_center_id_required_client_missing": "Prima seleziona l'azienda.",
                "ledger_account_id_required": "Selezionare un conto contabile.",
                "consignee_id_required": "La preghiamo di selezionare una persona responsabile.",
                "delivery_address_id_required": "Selezioni un indirizzo di consegna.",
                "billing_address_text_required": "L'indirizzo di fatturazione manca nei dati aziendali."
            },
            "tour": {
                "main": {
                    "description": "Nei primi campi, specifica un nome per la richiesta d'ordine, insieme alla tua azienda e al reparto per cui deve essere effettuato l'ordine. Il reparto viene filtrato in base all'azienda e il centro di costo in base al reparto.",
                    "organization": "Specifichi l'azienda, il reparto e il centro di costo per l'ordine di acquisto. L'elenco dei reparti è filtrato dall'azienda selezionata e l'elenco dei centri di costo è filtrato dal reparto selezionato.",
                    "address": "L'indirizzo di consegna sarà fornito al fornitore. Può scegliere tra gli indirizzi dell'azienda, della persona incaricata di ricevere la merce e i suoi indirizzi personali.",
                    "total": "Qui può vedere il valore totale del suo ordine d'acquisto, che è la somma dei prodotti selezionati.",
                    "delivery_date": "La data di consegna può essere registrata dopo il ricevimento della merce a scopo di documentazione. Non sarà inviata al fornitore.",
                    "product_selector": "Questo indica chi è responsabile della selezione del prodotto nella fase successiva. Dipende da vari ruoli predefiniti, per maggiori dettagli clicchi sull'icona informativa.",
                    "order_sender": "Questo mostra chi è responsabile dell'inserimento degli ordini, che dipende da vari ruoli predefiniti. Per maggiori dettagli, clicchi sull'icona Info.",
                    "consignee": "Selezioni la persona che riceverà la merce e confermi la ricezione nella fase finale dell'ordine di acquisto per completare il processo.",
                    "approvers": "Qui vede se è stata impostata l'approvazione per il centro di costo selezionato. Può anche aggiungere persone per l'approvazione.",
                    "product_search": "Qui può cercare un prodotto nel catalogo prodotti interno e aggiungerlo all'ordine di acquisto.",
                    "punchout": "Qui può selezionare i prodotti da marketplace integrati come Amazon o Mercateo. La selezione dei prodotti può essere trasmessa elettronicamente al marketplace dopo l'approvazione.",
                    "new_product": "Se il prodotto desiderato non è presente nel catalogo prodotti, può aggiungerlo all'ordine di acquisto qui.",
                    "save_btn": "Dopo aver inserito tutte le informazioni richieste, può avviare il processo dell'ordine d'acquisto e passare alla fase successiva."
                },
                "product": {
                    "pdf": "Qui può aggiungere documenti al prodotto.",
                    "remove": "Può anche rimuovere i prodotti dalla selezione cliccando qui.",
                    "more_actions": "Può documentare qui il numero d'ordine esterno al momento della ricezione.",
                    "tabs": "Qui può raggruppare la vista dei suoi prodotti per fornitore, centro di costo o gruppo di prodotti.",
                    "expand_view": "Qui può mostrare o nascondere le impostazioni avanzate per i prodotti e selezionare un centro di costo, un indirizzo di consegna o un gruppo di prodotti."
                },
                "new_product": {
                    "name": "Fornisca un nome significativo per il prodotto.",
                    "number": "Può anche inserire un numero di articolo per il prodotto.",
                    "supplier": "Selezioni il fornitore presso il quale il prodotto è offerto o presso il quale deve essere ordinato.",
                    "price": "Specifichi un'aliquota fiscale e un prezzo netto o lordo. L'altro prezzo sarà calcolato utilizzando l'aliquota fiscale.",
                    "unit": "Specificare l'unità di misura del prodotto, come \"articoli\", \"kg\" o \"litri\".",
                    "url": "Può aggiungere un'immagine URL per il prodotto e un link alla pagina del prodotto nel negozio, se disponibile.",
                    "offer": "Se esiste un'offerta per il prodotto, può inserire qui il numero e la data dell'offerta per visualizzarli sul PDF dell'ordine.",
                    "should_save": "Qui sceglie di aggiungere il prodotto creato al catalogo prodotti. Questo assicura che questo prodotto specifico sarà disponibile per gli ordini futuri.",
                    "save_btn": "Aggiunga il prodotto all'ordine d'acquisto quando ha inserito tutte le informazioni."
                },
                "cost_center_view": "I centri di costo possono anche essere assegnati a singoli prodotti in un ordine di acquisto. Gli indirizzi di consegna associati al centro di costo possono poi essere selezionati per il prodotto.",
                "product_group_view": "Ogni prodotto può essere assegnato ad un gruppo di prodotti. La persona responsabile del gruppo di prodotti riceverà un compito nella timeline per modificare o confermare la selezione del prodotto.",
                "send_order": {
                    "send_btn": "Nella fase di conferma dell'ordine, può inviare elettronicamente l'ordine o confermare l'ordine effettuato via e-mail con il fornitore.",
                    "more_actions": "Nelle opzioni per il fornitore selezionato, può generare un PDF d'ordine o un'e-mail con o senza PDF d'ordine per il fornitore. In questo modo, potrà effettuare l'ordine all'azienda o fare una richiesta via e-mail."
                },
                "delivery_btn": "Se ha ricevuto una consegna parziale o l'intero ordine, può indicarlo qui. Anche la conferma della ricezione della merce sarà annotata nella timeline."
            }
        }
    },
    "quantity": "Quantità",
    "price_netto": "Prezzo netto",
    "price_brutto": "Prezzo lordo",
    "unit": "Unità",
    "default_unit": "Articolo",
    "tenant": {
        "tab_general": "Generale",
        "description": "Inquilino",
        "main_language": "Lingua di fallback",
        "monthly_document_limit": "Documenti al mese",
        "base_price": "Prezzo base",
        "price_extra_document": "Prezzo di ogni documento aggiuntivo",
        "monthly_procurement_limit": "Ordini di acquisto al mese in prezzo base",
        "monthly_invoice_limit": "Fatture al mese nel Prezzo Base",
        "base_price_procurement": "Ordini di acquisto Prezzo base",
        "base_price_invoice": "Fatture Prezzo base",
        "price_extra_procurement": "Prezzo per ogni ordine di acquisto aggiuntivo",
        "price_extra_invoice": "Prezzo per ogni fattura aggiuntiva",
        "invoice_recognition_unit_price": "Prezzo per documento del riconoscimento delle fatture",
        "easybill_id": "ID Easybill",
        "bank_account": "Numero di conto",
        "bank_account_owner": "Titolare del conto",
        "bank_bic": "BIC",
        "bank_code": "Codice bancario",
        "bank_iban": "IBAN",
        "bank_name": "Nome della banca",
        "address": "Indirizzo di fatturazione",
        "sepa_agreement": "Accordo di addebito diretto SEPA",
        "sepa_agreement_date": "SEPA - Data di iscrizione del mandato",
        "sepa_mandate_reference": "Riferimento del mandato",
        "automatic_billing": "Generazione automatica della fatturazione",
        "automatic_billing_mail": "Invia automaticamente la fatturazione via e-mail",
        "bank": "Connessione bancaria",
        "datev": "DATEV",
        "billing_address_name": "Azienda",
        "billing_address_consignee_name": "Nome del destinatario",
        "billing_address_street": "Via e numero.",
        "billing_address_zipcode": "Codice postale",
        "billing_address_city": "Città",
        "billing_address_state": "Stato",
        "billing_address_country": "Paese",
        "billing_address_phone": "Numero di telefono",
        "contact_email": "Contatto Email",
        "billing_email": "Email di fatturazione",
        "contact": "Informazioni di contatto",
        "sepa": "Addebito diretto SEPA",
        "add_sepa": "Aggiungere l'addebito diretto SEPA",
        "remove_sepa": "Revoca dell'addebito diretto SEPA",
        "import_invoice_email": "Importazione automatica delle fatture via e-mail",
        "tab_mailbox": "Caselle postali",
        "debit_credit_reverse": "Scambia conto/contropartita"
    },
    "datev_access": {
        "description": "Integrazione DATEV",
        "label_log": "Azienda DATEV (datev_client_id)",
        "label_log_fields": "datev_client_id",
        "connected_info": "Connessione a DATEV stabilita.",
        "disconnected_info": "Connessione a DATEV rimossa.",
        "connected_client_info": "Connessione con l'azienda DATEV {datev_client_id} stabilita.",
        "disconnected_client_info": "Connessione con l'azienda DATEV {datev_client_id} disattivata."
    },
    "ms365_access": {
        "description": "Integrazione D365",
        "connected_info": "Connessione a D365 stabilita.",
        "disconnected_info": "Connessione a D365 rimossa.",
        "data_loaded_info": "Dati da D365 caricati manualmente.",
        "journal_created_info": "Il journal D365 è stato aggiunto.<br>Nome del Journal: {journal_name} <br>URL dell'org: {org_url}.",
        "journal_edited_info": "Il nome del journal D365 è stato modificato: {journal_name}."
    },
    "client": {
        "description": "Azienda",
        "min_approval": "Numero minimo di approvatori",
        "branch_required": "La filiale è richiesta per il modulo di fatturazione.",
        "department_required": "Il reparto è richiesto per il modulo della fattura.",
        "cost_center_required": "Il centro di costo è obbligatorio per il modulo d'ordine.",
        "client_number": "Numero",
        "consultant_number": "Numero del consulente",
        "ledger_account_length": "Lunghezza del conto contabile",
        "vat_number": "ID IVA",
        "address_name": "Nome",
        "consignee": "Nome del destinatario - Indirizzo di fatturazione",
        "dropoff_location": "Nome del luogo di consegna - Indirizzo di fatturazione",
        "address": "Via e numero civico - Indirizzo di fatturazione",
        "address_complement": "Via 2 - Indirizzo di fatturazione",
        "country": "Paese - Indirizzo di fatturazione",
        "zipcode": "Codice postale - Indirizzo di fatturazione",
        "city": "Città - Indirizzo di fatturazione",
        "phone_country_code": "Codice paese del telefono - Indirizzo di fatturazione",
        "phone_number": "Numero di telefono - Indirizzo di fatturazione",
        "can_delete_document_directly": "I documenti possono essere eliminati senza cancellare.",
        "default_product_tax": "Aliquota fiscale standard (%)",
        "default_product_currency": "Valuta predefinita",
        "default_product_unit": "Unità predefinita",
        "form_sequence": "name,email,client_number,currency,min_approval,user_select_product_id,user_send_order_id,user_clearing_id,user_consignee_id,user_invoice_manual_check_id,user_invoice_clearing_id",
        "page": {
            "tab_general": "Generale",
            "tab_billing_address": "Indirizzo di fatturazione",
            "tab_shipping_address": "Indirizzi di spedizione",
            "tab_default_roles": "Ruoli predefiniti",
            "tab_permissions": "Permessi",
            "tab_departments": "Reparti",
            "tab_cost_centers": "Centri di costo",
            "tab_ledger_accounts": "Conti del libro mastro",
            "tab_required_fields": "Campi obbligatori",
            "tab_datev_csv": "DATEV CSV Export",
            "new": "Nuova azienda",
            "select_logo": "Seleziona il logo",
            "select_logo_tooltip_content": "Aggiungi un logo che verrà stampato sui documenti degli ordini approvati. È meglio utilizzare un file immagine con sfondo trasparente.",
            "select_letterhead": "Seleziona intestazione",
            "select_letterhead_tooltip_content": "Aggiungi un'intestazione che verrà stampata sul documento d'ordine delle tue approvazioni.",
            "billing_address": "Indirizzo di fatturazione",
            "roles": "Predefiniti dei ruoli generali",
            "user_clearing_id_tooltip_content": "Per il chiarimento nei processi di ordinazione.",
            "user_invoice_clearing_id_tooltip_content": "Per il chiarimento durante la fatturazione.",
            "no_selection": "Non è stata scelta un'azienda specifica, quindi è condivisa da tutti.",
            "validation": {
                "name_required": "La preghiamo di inserire un nome.",
                "name_max_length": "Non deve superare i 50 caratteri.",
                "email_invalid": "Inserisca un indirizzo e-mail valido.",
                "client_number_invalid": "Inserisca un numero valido.",
                "min_approval_invalid": "La preghiamo di inserire un numero valido.",
                "address_incomplete": "La preghiamo di compilare i dati obbligatori relativi all'indirizzo.",
                "delivery_address_missing": "Aggiunga almeno un indirizzo di consegna.",
                "error_address_incomplete": "Si prega di completare i dati obbligatori per l'indirizzo di consegna."
            },
            "tour": {
                "name": "Inizia inserendo il nome della sua azienda.",
                "email": "Inserisca l'e-mail della sua azienda alla quale, ad esempio, deve essere inviata la consegna della fattura.",
                "timeline_assignment": "Può opzionalmente pre-assegnare persone responsabili per le varie fasi degli ordini di acquisto per l'azienda. Ad esempio, se c'è sempre una persona responsabile dell'approvazione degli ordini.",
                "billing_address": "L'indirizzo di fatturazione è necessario per l'ordine e può essere inviato al fornitore con l'ordine.",
                "shipping_address": "Qui può inserire uno o più indirizzi di consegna che possono essere selezionati in un ordine di acquisto.",
                "btn_save": "Dopo aver inserito tutte le informazioni richieste, aggiunga la società."
            }
        }
    },
    "client_form_config": {
        "field": "Campo",
        "form": "Modulo",
        "required": "Obbligatorio"
    },
    "department": {
        "description": "Reparto",
        "code": "Numero",
        "form_sequence": "name,code,client_id,user_select_product_id,user_send_order_id,user_clearing_id,user_consignee_id",
        "page": {
            "new": "Nuovo Reparto",
            "tab_general": "Generale",
            "tab_clients": "Aziende",
            "tab_cost_centers": "Centri di costo",
            "tab_approvers": "Approvatori",
            "tab_permissions": "Gruppi di autorizzazioni",
            "tab_employees": "Dipendenti",
            "tab_assignment": "Assegnazioni di ruolo",
            "validation": {
                "name_required": "La preghiamo di inserire un nome.",
                "email_required": "Inserisca un indirizzo e-mail valido."
            },
            "tour": {
                "name": "Inizia inserendo il nome del reparto.",
                "client": "Se assegna il reparto a un'azienda, il reparto può essere selezionato solo all'interno di quell'azienda quando crea un ordine d'acquisto.",
                "timeline_assignment": "Può designare i responsabili delle varie fasi degli ordini di acquisto per il reparto. Ad esempio, se una persona specifica è sempre responsabile della ricezione della merce per ogni reparto.",
                "cost_centers": "Qui può assegnare i centri di costo esistenti al reparto. Se non assegna alcun centro di costo, durante la creazione di ordini di acquisto per il reparto, potranno essere selezionati solo i centri di costo non assegnati ad alcun reparto.",
                "employees": "Può aggiungere diverse persone che fanno parte di questo reparto. Quando le persone di questo reparto creano ordini di acquisto, il reparto sarà precompilato.",
                "btn_save": "Una volta fornite tutte le informazioni, aggiunga il reparto."
            }
        }
    },
    "permission": {
        "description": "Gruppo di autorizzazione",
        "name": "Nome",
        "page": {
            "new": "Nuovo gruppo di autorizzazioni",
            "validation": {
                "name_required": "La preghiamo di inserire un nome."
            },
            "tour": {
                "name": "Qui può creare un gruppo di autorizzazioni. Può poi assegnare questo gruppo a varie entità (reparti, prodotti del catalogo prodotti, gruppi di prodotti, ecc.) Può anche assegnare uno o più gruppi di autorizzazioni alle persone. Se un'entità non deve avere alcuna restrizione di autorizzazione, non assegni un gruppo."
            },
            "no_selection": "Non è stata selezionata alcuna autorizzazione, quindi è visibile a tutti."
        }
    },
    "task_group": {
        "description": "Gruppo di compiti",
        "name": "Nome",
        "page": {
            "new": "Nuovo gruppo di compiti",
            "validation": {
                "name_required": "Si prega di inserire un nome."
            },
            "tour": {
                "name": "Qui puoi creare un gruppo di compiti. Puoi quindi assegnare compiti a questo gruppo."
            },
            "no_selection": "Nessun gruppo di compiti selezionato"
        }
    },
    "budget": {
        "description": "Bilanci",
        "label_log": "value€ (start - end)",
        "label_log_fields": "value,start,end",
        "value": "Importo",
        "template_value": "$1 €",
        "type_value": "galleggiante",
        "start": "Valido da",
        "end": "Valido fino a",
        "add_foreach_ledger_account": "Inserisci tutti i conti contabili",
        "component": {
            "validation": {
                "end_date_before_start_date": "La data di fine non può essere precedente alla data di inizio.",
                "end_date_required": "La data di fine non può essere vuota.",
                "time_overlap": "I bilanci non possono sovrapporsi nel tempo.",
                "field_missing": "Inserisca tutte le informazioni."
            }
        }
    },
    "address": {
        "description": "Indirizzo di consegna",
        "description_plural": "Indirizzi di consegna",
        "address_name": "Nome",
        "consignee": "Nome del destinatario",
        "dropoff_location": "Nome della località di consegna",
        "address": "Via e numero.",
        "address_complement": "Via 2",
        "complement": "Via 2",
        "country": "Paese",
        "zipcode": "Codice postale",
        "city": "Città",
        "phone_country_code": "Codice paese del telefono",
        "phone_number": "Numero di telefono",
        "component": {
            "new": "Nuovo indirizzo di consegna",
            "copy_btn": "Copia indirizzo di fatturazione",
            "required_field": "La preghiamo di compilare i campi obbligatori dell'indirizzo.",
            "phone_required": "La preghiamo di inserire entrambe le informazioni.",
            "no_address": "Nessun indirizzo di consegna"
        }
    },
    "cost_center": {
        "name": "Nome",
        "number": "Numero",
        "free_budget": "Budget disponibile",
        "description": "Centro di costo",
        "label_log": "cost_center_name",
        "budgets": "Bilanci",
        "form_sequence": "name,number",
        "page": {
            "new": "Nuovo Centro di costo",
            "department_tooltip_content": "Quali reparti sono autorizzati a utilizzare questo centro di costo.",
            "tab_general": "Generale",
            "tab_budgets": "Budget",
            "tab_shipping_address": "Indirizzi di spedizione",
            "tab_approvers": "Approvatori",
            "tab_permissions": "Gruppi di autorizzazioni",
            "tab_departments": "Dipartimenti",
            "tab_clients": "Aziende",
            "validation": {
                "name_required": "La preghiamo di inserire un nome.",
                "number_required": "La preghiamo di inserire un numero.",
                "number_length": "Deve essere lungo al massimo 36 caratteri e può avere solo spazi al centro.",
                "number_exists": "Il numero esiste già."
            },
            "tour": {
                "name_number": "Fornisca un nome e un numero unico per il centro di costo.",
                "budget": "È possibile assegnare diversi budget al proprio centro di costo e allocare i budget a un conto contabile.",
                "approver": "Definisce qui chi deve approvare gli ordini di acquisto con questo centro di costo. È possibile aggiungere altri approvatori al momento della creazione degli ordini di acquisto.",
                "department": "Qui può definire in quali reparti può essere utilizzato il centro di costo. Se non aggiunge alcun reparto, il centro di costo può essere selezionato in tutti i reparti.",
                "btn_save": "Dopo aver fornito tutte le informazioni necessarie, aggiunga il centro di costo al database."
            }
        }
    },
    "supplier": {
        "address": "Indirizzo",
        "external_id": "DATEV ID",
        "supplier_number": "Numero fornitore",
        "client_number": "Numero cliente",
        "delivery_in_days": "Tempi di consegna in giorni lavorativi",
        "delivery_terms": "Termini di consegna",
        "payment_terms": "Termini di pagamento",
        "vat_number": "ID IVA",
        "city": "Città",
        "iban": "IBAN",
        "bic": "BIC",
        "form_sequence": "name,email,city,address,external_id,delivery_terms,payment_terms,iban,bic,vat_number",
        "payment_method": "Metodo di pagamento",
        "payment_method_info": "Le fatture delle aziende fornitrici con il metodo di pagamento addebito diretto SEPA o carta di credito vengono contrassegnate come pagate direttamente",
        "page": {
            "new": "Nuovo fornitore",
            "department_tooltip_content": "Quali reparti sono autorizzati a utilizzare questo fornitore.",
            "delivery_in_days_tooltip_content": "Il tempo di consegna stimato serve per la ricezione della merce compito e-mail.",
            "notes_tooltip_content": "Questo promemoria viene visualizzato solo internamente su liftbase e non viene stampato sul PDF dell'ordine.",
            "external_id_tooltip_content": "L'ID del fornitore di un sistema di terze parti che ha integrato.",
            "client_number_tooltip_content": "Numero assegnato a voi da questo fornitore (opzionale).",
            "supplier_number_tooltip_content": "Numero che avete assegnato a questo fornitore.",
            "delivery_terms_tooltip_content": "I termini di pagamento e di consegna concordati con il fornitore saranno visualizzati sul PDF dell'ordine di acquisto.",
            "payment_terms_tooltip_content": "I termini di pagamento e di consegna concordati con il fornitore saranno visualizzati sul PDF dell'ordine di acquisto.",
            "tab_general": "Generale",
            "tab_delivery_info": "Informazioni sulla consegna",
            "tab_payment_info": "Informazioni di pagamento",
            "tab_permissions": "Gruppi di autorizzazioni",
            "tab_departments": "Dipartimenti",
            "tab_approvers": "Approvatori",
            "tab_clients": "ID specifici per azienda",
            "default_clients_ids": "Se un'azienda non è elencata in questa tabella con ID specifici, <br>verranno utilizzati i seguenti ID predefiniti: <br>ID DATEV: {external_id} <br>Numero cliente: {client_number}.",
            "no_default_id": "Nessun ID predefinito disponibile.",
            "validation": {
                "name_required": "La preghiamo di inserire un nome.",
                "name_length": "Deve essere lungo al massimo 50 caratteri.",
                "email_invalid": "Inserisca un indirizzo e-mail valido.",
                "supplier_number_invalid": "Inserisca un numero valido.",
                "vat_number_invalid": "Può contenere numeri, lettere maiuscole e minuscole, punti, spazi e trattini bassi. Deve essere lungo al massimo 15 caratteri.",
                "iban_invalid": "Deve avere una lunghezza minima di 15 e massima di 34 caratteri. Non può contenere spazi. Inizia con due lettere maiuscole, seguite da 2 numeri e poi da 11 a 30 caratteri alfanumerici.",
                "bic_invalid": "Deve essere lungo almeno 8 e al massimo 11 caratteri e contenere solo lettere maiuscole e numeri. I primi sei caratteri devono essere lettere.",
                "external_id_length": "L'ID DATEV deve contenere solo numeri interi e non può essere inferiore a 10000."
            },
            "tour": {
                "name": "Inserisca il nome del fornitore.",
                "email": "L'indirizzo e-mail del fornitore viene utilizzato, ad esempio, per la preparazione dei messaggi e-mail per l'inserimento dell'ordine.",
                "address": "Qui può fornire l'indirizzo del fornitore.",
                "external_id": "L'ID esterno è il numero del fornitore memorizzato in un sistema esterno. Questo numero viene utilizzato nell'API.",
                "terms": "Inserisca i termini di pagamento e di consegna concordati con il fornitore. Queste informazioni saranno visualizzate sul PDF dell'ordine d'acquisto.",
                "btn_save": "Una volta inserite tutte le informazioni, salvi il fornitore qui."
            }
        }
    },
    "user": {
        "name": "Nome",
        "client_id": "Azienda principale",
        "approval_limit": "Limite di approvazione",
        "roles": {
            "user-admin": "Amministratore",
            "user-price": "Può visualizzare i prezzi",
            "user-dashboard": "Può visualizzare il Dashboard",
            "user-change-order": "Può modificare l'ordine dopo l'approvazione",
            "user-see-all-orders": "Può vedere tutti gli ordini di acquisto",
            "user-see-all-invoices": "Tutte le aziende",
            "user-see-invoices": "Visualizza fatture:",
            "user-see-all-invoices-belonging-to-own-company": "Propria azienda",
            "user-see-own-invoices-and-involved": "Proprie e coinvolte",
            "user-can-change-invoices": "Modifica fattura successivamente",
            "user-see-client-addresses": "Può vedere tutti gli indirizzi aziendali",
            "user-export-invoice": "Può esportare le fatture in {export_name}",
            "user-can-delete-document-directly": "Può annullare/rifiutare i documenti senza chiarimento",
            "user-can-insert-product": "Salva nel catalogo prodotti"
        },
        "blocked": "Accesso bloccato",
        "token_issued_at": "Token API emesso alle",
        "form_sequence": "name,email,client_id,department_id,cost_center_id,location_id,approval_limit,roles,blocked",
        "page": {
            "tab_general": "Generale",
            "tab_roles": "Permessi",
            "tab_roles_general": "Autorizzazioni generali",
            "tab_roles_procurement": "Richieste di acquisto",
            "tab_roles_invoice": "Fatture",
            "tab_roles_block": "Blocchi",
            "tab_permissions": "Gruppi di autorizzazione",
            "tab_addresses": "Indirizzi di consegna",
            "tab_task_groups": "Gruppi di compiti",
            "tab_passkeys": "Passkeys",
            "new": "Nuovo utente",
            "block_btn": "Bloccare l'utente",
            "unblock_btn": "Sbloccare l'utente",
            "login_as_btn": "Asse di accesso",
            "no_permission": "Non è stato selezionato alcun gruppo di autorizzazione.",
            "task_group_permissions": "Autorizzazioni dai miei gruppi di attività:",
            "approval_limit_tooltip_content": "Definisca qui il limite di approvazione finale per questa persona. <br> Possono essere approvati importi superiori, ma devono essere approvati definitivamente da un'altra persona con un limite di approvazione superiore.",
            "approval_limit_placeholder": "Illimitato",
            "upload_signature_btn": "Selezionare la firma",
            "upload_signature_tooltip_content": "Aggiunga una firma che verrà stampata sul documento d'ordine dei suoi ordini d'acquisto approvati. <br/> Si consiglia di utilizzare un file immagine con sfondo trasparente.",
            "api_token_btn": "Generare il token API",
            "api_token_dialog_title": "Generare il token API",
            "api_token_dialog_content": "I token API sono necessari per l'integrazione di applicazioni di terze parti e consentono l'accesso ai dati per conto del rispettivo utente.",
            "api_token_dialog_date_placeholder": "Data di scadenza del gettone",
            "api_token_dialog_generate_btn": "Generare",
            "api_token_dialog_copy_btn": "Copia",
            "api_token_dialog_copy_info": "Copi e incolli il seguente token API nell'applicazione di terze parti:",
            "api_token_dialog_copied": "Copiato!",
            "language": "Lingua",
            "role_dashboard_tooltip": "Può essere selezionato solo se l'utente può visualizzare i prezzi.",
            "passkey": "Passkey",
            "passkeys": "Passkeys",
            "passkey_name": "Nome",
            "passkey_last_login": "Ultimo accesso",
            "passkey_dialog": {
                "title": "Aggiungere la chiave d'accesso",
                "info1": "<b>Cosa sono i Passkeys? </b><ul><li>Accede senza aspettare un'e-mail</li><li>Con l'impronta digitale, il suo volto o il PIN</li><li>Sicuro e conveniente</li></ul>",
                "info2": "<b>Impostare ora in 3 passi:</b><ol><li>Inserisca il nome, ad esempio \"PC Home Office\" o \"Notebook\"</li><li>Clicchi su \"Avanti\"</li><li>Segua le istruzioni del suo browser/sistema operativo</li></ol>.",
                "info3": "Le istruzioni passo-passo <a href=\"https://liftbase.de/handbuch/anmeldung/passkeys/\" target=\"_blank\">sono disponibili nel manuale</a>.",
                "name_input_label": "Nome della chiave di accesso",
                "name_input_placeholder": "Ad esempio, 'PC Home Office' o 'Notebook'.",
                "added_msg": "È stata aggiunta la chiave d'accesso.",
                "deleted_msg": "La chiave d'accesso è stata cancellata.",
                "exist_msg": "Ha già una chiave di accesso su questo dispositivo, provi con un altro dispositivo."
            },
            "validation": {
                "name_required": "La preghiamo di inserire un nome.",
                "name_exists": "Il nome esiste già.",
                "email_required": "Inserisca un indirizzo e-mail.",
                "email_invalid": "Inserisca un indirizzo e-mail valido.",
                "email_exists": "L'indirizzo e-mail esiste già.",
                "approval_limit_length": "Il limite di approvazione deve essere maggiore di 0 o vuoto."
            },
            "tour": {
                "name": "Inserisca il nome dell'utente, preferibilmente nome e cognome.",
                "email": "Inserisca l'indirizzo e-mail attraverso il quale l'utente può accedere.",
                "organization": "Assegna l'utente ad un'azienda, ad un reparto e ad un centro di costo. Queste informazioni saranno precompilate nel modulo dell'ordine d'acquisto.",
                "approval_limit": "Qui può definire un limite di approvazione per l'utente. Se un ordine d'acquisto ha un importo totale superiore, deve essere approvato definitivamente da una persona con un limite di approvazione più alto. Se non viene definito alcun limite, l'utente può approvare definitivamente tutti gli importi.",
                "signature": "Qui può aggiungere la firma dell'utente. Quando l'utente approva un ordine di acquisto, la sua firma sarà aggiunta al PDF dell'ordine di acquisto.",
                "roles": "Qui è possibile impostare le autorizzazioni per la persona, ad esempio se e quali processi o informazioni possono essere visualizzati o modificati. C'è anche l'opzione di bloccare la persona se, ad esempio, ha lasciato l'azienda.",
                "roles_price": "Specificare se l'utente può visualizzare i prezzi dei prodotti o il dashboard con gli approfondimenti sugli ordini di acquisto.",
                "role_change_order": "Può concedere ad un utente fidato il permesso di modificare gli ordini di acquisto dopo l'approvazione, senza rimandarli indietro per chiarimenti ed evitando così una nuova approvazione. Questo può essere utile, ad esempio, per modificare in seguito gli indirizzi di consegna o i prezzi dei prodotti.",
                "admin": "Scelga se questa persona deve avere accesso all'area di amministrazione, ad esempio per aggiungere altri utenti, dati anagrafici o impostazioni.",
                "block": "Selezioni questa opzione se vuole impedire ad un utente di accedere a liftbase, ad esempio se ha temporaneamente lasciato l'azienda.",
                "api_token": "I token API sono necessari per l'integrazione di applicazioni di terze parti e consentono l'accesso ai dati per conto del rispettivo utente.",
                "addresses": "Può aggiungere indirizzi di consegna personali per l'utente, ad esempio se lavora da casa e vuole che i prodotti vengano consegnati lì.",
                "btn_save": "Salvi l'utente e le informazioni associate.",
                "profile_signature": "Può caricare la sua firma qui. Quando approva gli ordini di acquisto, la sua firma sarà aggiunta al PDF dell'ordine di acquisto.",
                "profile_addresses": "Può aggiungere indirizzi di consegna personali per l'utente, ad esempio se lavora da casa e vuole che i prodotti vengano consegnati lì.",
                "profile_btn_save": "Salvi le informazioni associate."
            }
        }
    },
    "lb_user": {
        "description": "Utente"
    },
    "posting_key": {
        "name": "Nome",
        "tax": "Aliquota fiscale (%)",
        "number": "Key",
        "form_sequence": "name,number,tax",
        "page": {
            "new": "Nuovo Posting key",
            "validation": {
                "name_required": "La preghiamo di inserire un nome.",
                "number_required": "Inserisca un numero valido.",
                "number_length": "Può contenere fino a 9 numeri a partire da 1."
            },
            "tour": {
                "name": "Inserisca la chiave e l'aliquota fiscale associata."
            }
        }
    },
    "ledger_account": {
        "name": "Nome",
        "description": "Descrizione",
        "form_sequence": "name,number,description",
        "page": {
            "new": "Nuovo conto del libro mastro",
            "validation": {
                "name_required": "Inserisci un nome.",
                "number_required": "Inserisca un numero valido.",
                "number_length": "Possono contenere solo numeri e non possono iniziare con 0.",
                "tax_required": "Inserisca un'aliquota fiscale valida.",
                "tax_length": "Il valore percentuale può avere solo due cifre con una virgola e al massimo due cifre decimali."
            },
            "tour": {
                "name": "Inserisca il numero e il nome del conto del libro mastro."
            }
        }
    },
    "profit_center": {
        "name": "Nome",
        "form_sequence": "name,number",
        "page": {
            "new": "Nuovo centro di profitto",
            "validation": {
                "name_required": "Inserisci un nome.",
                "number_required": "Inserisci un numero valido.",
                "number_length": "Deve contenere solo numeri e non iniziare con 0 per numeri a più cifre.",
            },
            "tour": {
                "name": "Specifica il numero e il nome del centro di profitto."
            }
        }
    },
    "tax_group": {
        "code": "ID",
        "name": "Nome",
        "form_sequence": "name,code,client_id",
        "page": {
            "new": "Nuovo gruppo imposta sulle vendite",
            "validation": {
                "name_required": "Inserisci un nome.",
                "code_required": "Inserisci un ID.",
                "client_required": "Seleziona un cliente.",
            },
            "tour": {
                "name": "Specifica l'ID e il nome del gruppo imposta sulle vendite."
            }
        }
    },
    "financial_dimension": {
        "code": "ID",
        "name": "Nome",
        "form_sequence": "name,code",
        "page": {
            "new": "Nuovo Dipendente",
            "validation": {
                "name_required": "Si prega di inserire un nome.",
                "code_required": "Si prega di inserire un ID.",
                "client_required": "Si prega di selezionare un'azienda."
            },
            "tour": {
                "name": "Inserisci l'ID e il nome del dipendente."
            }
        }
    },
    "tax_item_group": {
        "code": "ID",
        "tax_code": "Codice Fiscale",
        "name": "Denominazione",
        "form_sequence": "name,code,tax_code,client_id",
        "page": {
            "new": "Nuovo Gruppo di Elementi Fiscali",
            "validation": {
                "name_required": "Si prega di inserire un nome.",
                "code_required": "Si prega di inserire un ID.",
                "tax_code_required": "Si prega di inserire un Codice Fiscale.",
                "client_required": "Si prega di selezionare un'azienda.",
            },
            "tour": {
                "name": "Inserisci l'ID e la denominazione del gruppo di imposte sul valore aggiunto."
            }
        }
    },
    "approver": {
        "description": "Approval",
        "label_log": "lb_user_name",
        "table_log": "lb_user",
        "approver_required": "Selezioni un approvatore.",
        "is_required": "Obbligatorio",
        "is_required_info": "Deve anche approvare, anche se i revisori precedenti avevano già il limite di approvazione necessario."
    },
    "invoice": {
        "description": "Fattura",
        "name": "Nome",
        "creator_id": "Caricato da",
        "created_at": "Data caricamento",
        "number": "Numero di fattura",
        "invoice_number_formatted": "Numero interno RE",
        "due_date": "Data di scadenza",
        "delivery_date": "Data di consegna",
        "invoice_date": "Data fattura",
        "tax": "Tax Rate %",
        "total_brutto": "Totale lordo",
        "total_netto": "Totale netto",
        "total_netto_abbr": "-Rete",
        "fix_recognition": "Insoddisfatto del riconoscimento dei dati",
        "fix_recognition_checkbox": "Non sono soddisfatto del riconoscimento dei dati.",
        "paid": "Già pagato",
        "review_status": "Risultato della revisione",
        "review_status_options": {
            "content_rejection": "Fattura respinta per motivi contenutistici",
            "insolvency_rejection": "Fattura respinta per motivi di diritto fallimentare",
            "further_review_required": "Fattura da sottoporre a ulteriore revisione",
            "approved": "Fattura approvata"
        },
        "currency": "Valuta",
        "notes": "Note",
        "order_notes": "Note sull'ordine",
        "discount_percentage": "Sconto 1 (%)",
        "discount_amount": "Sconto 1 - Importo",
        "discount_days": "Sconto 1 - Giorni",
        "discount_payment_date": "Sconto 1 - Data",
        "discount_percentage2": "Sconto 2 (%)",
        "discount_amount2": "Sconto 2 - Importo",
        "discount_days2": "Sconto 2 - Giorni",
        "discount_payment_date2": "Sconto 2 - Data",
        "type_discount_amount": "currency",
        "type_discount_amount2": "currency",
        "export_individual_items": "Esportare voci singole",
        "export_individual_items_tooltip": "Se questa casella è selezionata, le posizioni vengono trasmesse al posto dei dati di intestazione.",
        "status": "Stato",
        "statuses": {
            "open": "Riconoscimento delle fatture",
            "export": "Pronto per l'esportazione",
            "declined": "Scartato",
            "declined-exported": "Annullato-Esportato",
            "completed": "Completato",
            "approval": "Controllo manuale",
            "financial-approval": "Approvazione",
            "clearing": "Chiarimento contabile",
            "accounting": "Contabilità"
        },
        "page": {
            "upload": {
                "title": "Caricare nuove fatture",
                "info": "Trascini e rilasci o clicchi qui per caricare una o più fatture (*.pdf fino a 10MB).",
                "info_attachments": "Trascina e rilascia o clicca qui per caricare uno o più allegati (fino a 10 MB per file).",
                "create_btn": "Avviare il riconoscimento delle fatture",
                "file_invalid": "La preghiamo di caricare solo file PDF o XML fino a 10 MB.",
                "no_preview_available": "Nessuna anteprima disponibile per questo tipo di file. <br>Scarica il file e aprilo sul tuo computer.",
                "tour": {
                    "before_attach": "Qui può caricare una o più fatture trascinando e rilasciando o cliccando all'interno di quest'area.",
                    "after_attach_title": "Avviare il riconoscimento delle fatture",
                    "after_attach": "Qui può avviare il riconoscimento automatico di tutte le fatture caricate. Verrà quindi reindirizzato alla panoramica della prima fattura. Le altre fatture saranno disponibili nell'elenco delle attività."
                }
            },
            "new": "Nuova fattura",
            "payment_conditions": "Condizioni di pagamento",
            "discount1": "Sconto 1",
            "discount2": "Sconto 2",
            "discount_percentage": "Percentuale",
            "discount_amount": "Importo",
            "discount_days": "Giorni",
            "discount_payment_date": "Data",
            "recognition_data_title": "Dati grezzi di riconoscimento",
            "no_recognition_data": "Nessun dato riconosciuto.",
            "repeat_recognition_btn": "Ripeti riconoscimento documento",
            "repeat_recognition_confirm_btn": "Conferma",
            "repeat_recognition_confirm_msg": "I dati esistenti nel modulo verranno sovrascritti. Sei sicuro di voler procedere?",
            "repeat_recognition_confirm_title": "Ripeti riconoscimento documento",
            "repeat_recognition_feedback_msg": "Il riconoscimento del documento viene elaborato in background e potrebbe richiedere alcuni secondi.",
            "select_columns_btn": "Seleziona colonne",
            "export_to_datev_btn": "Invia al DATEV",
            "export_to_datev_csv_btn": "Esporta DATEV CSV",
            "export_to_ms365_btn": "Invia al D365",
            "export_btn_incomplete_form_tooltip": "Si prega di completare i dati della fattura.",
            "export_btn_send_to_clearing_tooltip": "Per ritrasmettere a D365, si prega di rinviare alla contabilità tramite il passaggio 'Risoluzione'.",
            "export_error": "<b>La fattura non può essere inviata</b>.<br>",
            "no_export_config_datev_tooltip": "Nessuna connessione a <b>{export_name}</b> è stata configurata. <br>Nel file di configurazione per l'azienda selezionata <b>{client_name}</b> non è stato assegnato alcun cliente DATEV. <br>Se necessario, contattare una persona con diritti amministrativi <br>per configurare la connessione nell'area amministrativa.",
            "no_export_config_tooltip": "Non è stata impostata alcuna connessione a {export_name}. <br>Contatti una persona con diritti amministrativi per impostare la connessione nell'area amministrativa.",
            "confirm_before_leave_msg": "La sua fattura non è stata salvata. È sicuro di voler lasciare la pagina?",
            "can_not_edit": "Non ha il permesso di modificare i dati. Per farlo, la preghiamo di inviare la fattura in chiarimento utilizzando le Azioni.",
            "recognized_client_id": "L'azienda è stata identificata correttamente sulla base del <b>ID IVA</b> e del <b>nome</b>.<br> <b>Azienda:</b> {client_name}",
            "user_client_id": "L'azienda è stata assegnata in base al <b>profilo utente</b> dell'uploader.<br><b>Azienda:</b> {client_name}",
            "no_client_recognized": "Non è stata trovata nessuna azienda sulla base del <b>ID IVA</b> o del <b>nome</b>.<br> Si prega di verificare se i dati dell'azienda in liftbase corrispondono ai dati della fattura.",
            "recognized_supplier_id": "Il fornitore è stato identificato correttamente sulla base del <b>IBAN</b>, <b>ID IVA</b> e del <b>nome</b>.<br> <b>Fornitore:</b> {supplier_name}",
            "no_supplier_recognized": "Non è stato trovato alcun fornitore sulla base del <b>ID IVA</b> o del <b>nome</b>.<br> Si prega di verificare se i dati del fornitore in liftbase corrispondono ai dati della fattura.",
            "total_brutto_missing": "Inserisca il totale lordo.",
            "total_brutto_zero": "Non deve avere un importo di 0 euro.",
            "total_brutto_does_not_match_with_positions": "L'importo totale deve corrispondere alla somma delle posizioni.",
            "combination_invalid": "La combinazione di conto contabile, centro di costo e centro di profitto non è consentita in D365.",
            "possible_combinations": "Combinazioni possibili",
            "current_combination": "Combinazione attuale",
            "combination": "Combinazione",
            "min_approval_one_person": "1 persona",
            "min_approval_qty": "{qty} persone diverse",
            "min_approval_error_msg": "L'approvazione non è sufficiente per questa fattura. Si prega di aggiungere un'altra persona per l'approvazione. Questa fattura deve essere approvata da <b>almeno {min_approval_msg}</b>.",
            "min_approval_error_title": "Approvazione insufficiente",
            "no_approval_limit_msg": "Il tuo limite di approvazione non è sufficiente per questa fattura. Si prega di aggiungere un'altra persona con il limite di approvazione necessario.",
            "no_approval_limit_title": "Limite di approvazione",
            "confirm_without_approval_limit_msg": "Nessuna delle persone selezionate ha il limite di approvazione necessario per l'importo di questa fattura. Le approvazioni necessarie possono essere aggiunte in seguito. Sei sicuro di voler procedere?",
            "confirm_without_approval_limit_title": "Limite di approvazione",
            "lines": {
                "add_tooltip": "Aggiungere una linea di fatturazione",
                "get_from_recognition": "Prendi dal riconoscimento documento",
                "legend": {
                    "description": "Leggenda",
                    "no_order": "Articolo non incluso nell'ordine.",
                    "variance": "Varianza nelle colonne contrassegnate.",
                    "not_invoiced": "L'articolo ordinato non è presente nella fattura.",
                    "matched": "L'articolo nell'ordine e sulla fattura è identico."
                },
                "form": {
                    "item": "Articolo",
                    "new": "Nuova linea di fatturazione",
                    "validation": {
                        "description_required": "La preghiamo di inserire un nome.",
                        "item_number_required": "Inserisca un numero di articolo.",
                        "tax_percentage_required": "La preghiamo di selezionare una tassa valida.",
                        "unit_net_price_required": "Inserisca un numero valido.",
                        "quantity_required": "Inserisca un numero valido."
                    },
                    "tour": {
                        "name": "Fornisca un nome significativo per il prodotto.",
                        "number": "Qui può inserire il numero di articolo del prodotto.",
                        "price": "Inserisca un'aliquota fiscale e un prezzo netto unitario. Il prezzo lordo verrà calcolato utilizzando l'aliquota fiscale."
                    }
                },
                "table": {
                    "source": "Fonte",
                    "item_number": "Articolo No.",
                    "name": "Nome",
                    "quantity": "Quantità",
                    "tax": "Imposte (%)",
                    "netto_price": "Unità Netto (€)",
                    "total_netto_price": "Totale netto (€)",
                    "tax_amount": "Imposta totale (€)",
                    "total_brutto_price": "Totale lordo (€)",
                    "cost_center": "Centro di costo",
                    "profit_center": "Centro di profitto",
                    "ledger_account": "Conto generale",
                    "tax_group": "Gruppo fiscale",
                    "tax_item_group": "Gruppo articoli fiscali",
                    "open_bcase": "Documento aperto",
                    "no_order_position": "Non sono state trovate posizioni d'ordine. Assegni un <b>ordine di acquisto (PO)</b> e un <b>fornitore</b> alla fattura.",
                    "copy_from_procurement": "Inserire tutti gli articoli dall'ordine d'acquisto",
                    "no_order_tooltip": "Non è ancora stato collegato alcun ordine di acquisto.",
                    "no_supplier_tooltip": "Non c'è ancora nessun fornitore collegato.",
                    "validation_error": "La preghiamo di correggere gli errori nella tabella."
                }
            },
            "export_dialog": {
                "title": {
                    "D365": "Crea giornale",
                    "DATEV": "Invio a DATEV",
                    "DATEV CSV": "Esportazione CSV DATEV",
                    "export_cancelled_invoices": "Esporta fatture annullate"
                },
                "description": "I seguenti documenti saranno inviati a {export_name}.",
                "description_csv": "I seguenti documenti verranno esportati per {export_name}.",
                "btn_confirm": "Invia",
                "btn_confirm_csv": "Esporta",
                "btn_finish": "Chiudi",
                "export_feedback_csv": "Esportato",
                "export_feedback": "Inviato",
                "exported_csv": "Esportato",
                "exported": "Inviato",
                "continue_background": "Continua in background",
                "exported_at": "Ultima esportazione",
                "is_loading": "Invio in corso",
                "is_loading_csv": "Esportazione in corso"
            },
            "select_columns_dialog": {
                "title": "Seleziona colonne",
                "select_fields": "Le seguenti colonne verranno visualizzate sotto <i>Le mie attività</i> e <i>Tutti i documenti</i>.",
                "btn_confirm": "Applica",
                "btn_finish": "Chiudi"
            },
            "copy_procurement_dialog": {
                "title": "Copiare i dati dall'ordine d'acquisto",
                "message": "Desidera copiare alcuni dati dall'ordine di acquisto a questa fattura?",
                "overwrite_checkbox": "Sovrascrive i dati esistenti nel modulo.",
                "select_supplier_msg": "Selezioni il fornitore da cui desidera copiare i dati:",
                "select_supplier_placeholder": "Selezionare il fornitore",
                "confirm_btn": "Confermare"
            },
            "validation": {
                "invoice_date_required": "Inserisca la data della fattura.",
                "due_date_required": "Inserisca una data di scadenza.",
                "due_date_invalid1": "La data di scadenza deve essere superiore alla data del documento.",
                "delivery_date_required": "Inserisca una data di consegna.",
                "ledger_account_required": "Selezioni un conto contabile.",
                "branch_required": "Si prega di selezionare una filiale.",
                "posting_key_required": "Selezioni una chiave di distacco.",
                "supplier_required": "Selezioni un fornitore.",
                "cost_center_required": "Selezioni un centro di costo.",
                "profit_center_required": "Si prega di selezionare un centro di profitto.",
                "employee_required": "Si prega di selezionare un dipendente.",
                "d365_department_required": "Si prega di selezionare un dipartimento D365.",
                "financial_dimension_required": "Seleziona una dimensione finanziaria.",
                "client_required": "Selezioni un'azienda.",
                "number_required": "Inserisca il numero della fattura.",
                "number_invalid_d365": "Non deve contenere più di 20 caratteri.",
                "number_invalid": "Deve contenere fino a 36 caratteri alfanumerici, senza dieresi, e i caratteri $ % & * + - /.",
                "notes_length": "Non deve superare i 120 caratteri.",
                "discount_percentage_invalid1": "La percentuale di sconto deve essere maggiore di 0.",
                "discount_percentage_invalid2": "Lo sconto 1 deve essere maggiore dello sconto 2.",
                "discount_amount_invalid1": "Per gli importi delle fatture positive sono accettabili solo gli importi positivi.",
                "discount_amount_invalid2": "Per gli importi negativi delle fatture sono accettabili solo gli importi negativi.",
                "discount_amount_invalid3": "Lo sconto 1 deve essere maggiore dello sconto 2.",
                "discount_amount_invalid4": "Lo sconto deve essere inferiore all'importo totale.",
                "discount_amount_invalid5": "La somma degli importi degli sconti deve essere inferiore all'importo.",
                "discount_date_invalid1": "Le date di sconto 1 e 2 devono essere comprese tra la fattura e la data di scadenza.",
                "discount_date_invalid2": "La data di sconto 1 deve essere precedente alla data di sconto 2.",
                "missing_discount_field": "Tutti i campi dello sconto devono essere compilati."
            }
        }
    },
    "invoice_line": {
        "item_number": "Numero di articolo",
        "quantity": "Quantità",
        "unit_net_price": "Unità Prezzo netto",
        "tax_percentage": "Imposta"
    },
    "location": {
        "name": "Nome",
        "number": "Numero",
        "address": "Indirizzo",
        "form_sequence": "name,number,address"
    },
    "spreadsheet": {
        "add_row": "Aggiungi linea",
        "download": "Scarica la Tabella",
        "save": "Salva la tabella"
    },
    "audit_log": {
        "description": "Modifiche",
        "history": "Modifiche",
        "table": "Tabella",
        "lb_user_id": "Utente",
        "data_before": "Prima",
        "data_after": "Dopo",
        "created_at": "Data",
        "type": {
            "insert": "Aggiunto",
            "update": "Modificato",
            "delete": "Rimosso"
        },
        "msg": {
            "deleted": "rimosso",
            "inserted": "aggiunto",
            "was_deleted": "È stato rimosso.",
            "was_inserted": "È stato aggiunto."
        },
        "none": "nessuno",
        "empty": "Non sono disponibili modifiche."
    },
    "txt_before_leave_page": "Le sue modifiche potrebbero non essere salvate. È sicuro di voler procedere?",
    "easybill_invoice": {
        "from_tenant": "Da parte dell'inquilino",
        "no_invoice": "Non è stata ancora creata alcuna fattura.",
        "number": "Numero",
        "service_date": "Periodo",
        "from": "Da",
        "until": "a",
        "total": "Totale",
        "due_date": "Data di scadenza",
        "status": "Stato",
        "paid_at": "Pagato su",
        "payment_missing": "In attesa",
        "sepa": "SEPA",
        "tour": {
            "invoice_table": "Qui può trovare una panoramica dettagliata di tutte le fatture di liftbase, compreso il loro stato. Le fatture possono essere scaricate tramite un pulsante."
        }
    },
    "configuration": {
        "description": "Impostazioni",
        "sepa_number": "Numero di identificazione del creditore",
        "sepa_mandate_reference": "Riferimento del mandato",
        "remove_sepa_msg": "È sicuro di voler revocare l'addebito diretto SEPA?",
        "remove_sepa_btn": "Revocare",
        "system-modules-procurement": "Modulo - Richieste di acquisto",
        "system-modules-invoice": "Modulo - Fatture",
        "system-modules-permissions": "Modulo - Gruppi di autorizzazione",
        "system-modules-multilingualism": "Modulo - Dati anagrafici multilingue",
        "system-modules-invoice-export": "Modulo - Esportazione fatture a",
        "purchaseorder-defaults-selectproduct": "Impostazioni di ruolo - Persona “Selezione prodotto”",
        "purchaseorder-defaults-sendorder": "Impostazioni di ruolo - Persona “Invio ordine”",
        "purchaseorder-defaults-clearing": "Impostazioni di ruolo - Persona “Chiarimenti acquisto”",
        "purchaseorder-defaults-consignee": "Impostazioni di ruolo - Persona “Ricevimento merci”",
        "invoice-defaults-manual_check": "Impostazioni di ruolo - Persona “Verifica manuale (fattura)”",
        "invoice-defaults-clearing": "Impostazioni di ruolo - Persona “Chiarimenti contabilità”",
        "purchaseorder-defaults-init_seq": "Richieste di acquisto - Numerazione ordini a partire da",
        "purchaseorder-product-require_product_group": "Richieste di acquisto - Tutti i prodotti devono essere assegnati a un gruppo di prodotti.",
        "purchaseorder-product-require_product_selection": "Richieste di acquisto - Processo di richiesta di acquisto",
        "email-send-order": "Ordini - Invio e-mail",
        "purchaseorder-pdf-free-text": "Ordini - PDF d'ordine",
        "type_email-send-order": "html-i18n",
        "type_purchaseorder-pdf-free-text": "html-i18n",
        "invoice-recognition-lines": "Fatture - Creazione automatica delle righe di fattura dal riconoscimento dei documenti.",
        "invoice-form-procurement-required": "Fatture - Collegamento alla richiesta d'acquisto necessario.",
        "notifications-mail-interval": "Notifiche - Intervallo e-mail in minuti",
        "punchout-amazon-identity": "Integrazioni - Punch-out Amazon - Identità",
        "punchout-amazon-secret": "Integrazioni - Punch-out Amazon - Segreto",
        "punchout-amazon-url": "Integrazioni - Punch-out Amazon - URL Punch-out",
        "punchout-amazon-order-url": "Integrazioni - Punch-out Amazon - URL Ordine",
        "punchout-amazon-email": "Integrazioni - Punch-out Amazon - E-Mail",
        "punchout-amazon-supplier": "Integrazioni - Punch-out Amazon - Fornitore predefinito",
        "punchout-mercateo-identity": "Integrazioni - Punch-out Mercateo - Identità",
        "punchout-mercateo-secret": "Integrazioni - Punch-out Mercateo - Segreto",
        "punchout-mercateo-url": "Integrazioni - Punch-out Mercateo - URL Punch-out",
        "punchout-mercateo-order-url": "Integrazioni - Punch-out Mercateo - URL Ordine",
        "punchout-mercateo-email": "Integrazioni - Punch-out Mercateo - E-Mail",
        "punchout-mercateo-supplier": "Integrazioni - Punch-out Mercateo - Fornitore predefinito",
        "punchout-rajapack-identity": "Integrazioni - Punch-out RAJA - Identità",
        "punchout-rajapack-secret": "Integrazioni - Punch-out RAJA - Segreto",
        "punchout-rajapack-url": "Integrazioni - Punch-out RAJA - URL Punch-out",
        "punchout-rajapack-order-url": "Integrazioni - Punch-out RAJA - URL Ordine",
        "punchout-rajapack-email": "Integrazioni - Punch-out RAJA - E-Mail",
        "punchout-rajapack-supplier": "Integrazioni - Punch-out RAJA - Fornitore predefinito",
        "punchout-conrad-identity": "Integrazioni - Punch-out Conrad - Identità",
        "punchout-conrad-secret": "Integrazioni - Punch-out Conrad - Segreto",
        "punchout-conrad-url": "Integrazioni - Punch-out Conrad - URL Punch-out",
        "punchout-conrad-order-url": "Integrazioni - Punch-out Conrad - URL Ordine",
        "punchout-conrad-email": "Integrazioni - Punch-out Conrad - E-Mail",
        "punchout-conrad-supplier": "Integrazioni - Punch-out Conrad - Fornitore predefinito",
        "punchout-thgeyer-identity": "Integrazioni - Punch-out TH-GEYER - Identità",
        "punchout-thgeyer-secret": "Integrazioni - Punch-out TH-GEYER - Segreto",
        "punchout-thgeyer-url": "Integrazioni - Punch-out TH-GEYER - URL Punch-out",
        "punchout-thgeyer-supplier": "Integrazioni - Punch-out TH-GEYER - Fornitore predefinito",
        "punchout-reichelt-identity": "Integrazioni - Punch-out Reichelt - Identità",
        "punchout-reichelt-secret": "Integrazioni - Punch-out Reichelt - Segreto",
        "punchout-reichelt-url": "Integrazioni - Punch-out Reichelt - URL Punch-out",
        "punchout-reichelt-supplier": "Integrazioni - Punch-out Reichelt - Fornitore predefinito",
        "validation": {
            "bic_invalid": "Inserisca un BIC valido.",
            "iban_invalid": "Inserisca un numero IBAN valido."
        },
        "datev": {
            "description": "DATEV",
            "connection": "Collegamento con DATEV",
            "status_active": "Attivo",
            "status_inactive": "Inattivo",
            "from": "Da",
            "until": "Fino a quando",
            "load_clients": "Caricare i clienti DATEV",
            "remove_connection": "Rimuovere la connessione",
            "deactivate_connection": "Disattiva connessione",
            "add_connection": "Impostare la connessione",
            "connected_by": "Connessione stabilita da {name}.",
            "apps_manager": "Qui può gestire le <a href=\"https://apps.datev.de/tokrevui\" target=\"_blank\">applicazioni collegate a DATEV</a>.",
            "msg_no_clients": "Non sono state trovate voci o lei non dispone dei permessi necessari o la connessione deve essere configurata di nuovo.",
            "fiscal_year_start": "Inizio dell'anno fiscale",
            "account_length": "Lunghezza del conto",
            "is_accounts_payable_ledger_available": "Registro dei conti pagabili",
            "is_accounts_receivable_ledger_available": "Registro dei crediti",
            "client_number": "Numero cliente",
            "consultant_number": "Numero del consulente",
            "client_name": "Cliente",
            "liftbase_client": "Azienda liftbase",
            "save_client": "Salva l'azienda",
            "change_client": "Cambia azienda"
        },
        "ms365": {
            "description": "D365",
            "connection": "Connessione a D365",
            "status_active": "attivo",
            "status_inactive": "inattivo",
            "connect_and_load_data": "Stabilire una connessione e caricare i dati",
            "connect": "Stabilire una connessione",
            "load_data": "Carica tutti i dati",
            "remove_connection": "Rimuovi Connessione",
            "add_connection": "Configura Connessione",
            "no_client_found": "Nessun cliente D365 trovato.",
            "msg_no_clients": "Nessun elemento trovato o mancano le autorizzazioni necessarie o la connessione deve essere configurata nuovamente.",
            "client_number": "Numero Aziendale D365",
            "client_name": "Azienda D365",
            "liftbase_client": "Azienda liftbase",
            "save_client": "Salva Azienda",
            "change_client": "Modifica Azienda",
            "sync_running": "I dati stanno caricando. Potrebbe richiedere alcuni minuti.",
            "last_sync_at": "Ultimo caricato il {last_sync_at}",
            "journal_name": "Journal Name",
            "org_url": "Org URL",
        },
        "datev-csv": {
            "description": "DATEV CSV",
        },
        "template": {
            "module-procurement": {
                "group": "Moduli",
                "label": "Ordine di acquisto",
                "tour": [
                    {
                        "target": ".step-moduli",
                        "content": "<p>Selezioni i moduli liftbase che desidera attivare. Può trovare informazioni sui costi su <a href=\"liftbase.de/preise\" target=\"_blank\">liftbase.de/preise</a>.</p>"
                    }
                ]
            },
            "module-invoice": {
                "group": "Moduli",
                "label": "Fattura"
            },
            "module-permissions": {
                "group": "Moduli",
                "label": "Gruppo di autorizzazione"
            },
            "module-multilingualism": {
                "group": "Moduli",
                "label": "Dati principali multilingue"
            },
            "insolvency-law-fields": {
                "group": "Moduli",
                "label": "Mostra campi di diritto fallimentare"
            },
            "module-invoice-export": {
                "group": "Moduli",
                "label": "Esportazione fattura a",
                "options": [
                    {
                        "value": "datev",
                        "content": "DATEV"
                    },
                    {
                        "value": "ms365",
                        "content": "D365"
                    },
                    {
                        "value": "datev-csv",
                        "content": "DATEV CSV"
                    }
                ]
            },
            "procurement-select-product": {
                "group": "Ruolo predefinito",
                "label": "Persona \"Selezione del prodotto",
                "tour": [
                    {
                        "target": ".assegnazione dei passi",
                        "content": "<p>Qui può impostare i ruoli predefiniti globali. Se non sono stati definiti ruoli predefiniti per l'azienda o il reparto, le persone inserite qui saranno assegnate alle rispettive fasi del flusso di lavoro degli ordini di acquisto e delle fatture.</p>"
                    }
                ]
            },
            "procurement-send-order": {
                "group": "Ruolo predefinito",
                "label": "Persona \"Ordine\""
            },
            "procurement-clearing": {
                "group": "Ruolo predefinito",
                "label": "Persona \"Chiarimenti sull'acquisto\"",
                "hint": "Per chiarire i problemi nei processi di approvvigionamento."
            },
            "procurement-consignee": {
                "group": "Ruolo predefinito",
                "label": "Persona \"Ricevimento della merce\""
            },
            "invoice-manual-check": {
                "group": "Ruolo predefinito",
                "label": "Persona \"Controllo manuale (fattura)\"."
            },
            "invoice-clearing": {
                "group": "Ruolo predefinito",
                "label": "Persona \"Chiarimento contabile",
                "hint": "Per risolvere i problemi durante l'elaborazione delle fatture."
            },
            "procurement-init-seq": {
                "group": "Ordini di acquisto",
                "label": "Numeri d'ordine iniziali",
                "hint": "Inserisca un numero da cui far partire i numeri d'ordine.",
                "validation": {
                    "interval": "Deve essere compreso tra 0 e 999999999.",
                    "type": "Deve contenere solo numeri interi."
                },
                "tour": [
                    {
                        "target": ".step-procurement",
                        "content": "Qui troverà le impostazioni relative agli ordini di acquisto. Configura il numero iniziale per gli ordini di acquisto e se tutti i prodotti negli ordini di acquisto devono essere assegnati ad un gruppo di prodotti."
                    }
                ]
            },
            "procurement-require-product-group": {
                "group": "Ordini di acquisto",
                "label": "Tutti i prodotti devono appartenere a un gruppo di prodotti."
            },
            "procurement-require-product-selection": {
                "group": "Ordini di acquisto",
                "label": "Ordini di acquisto",
                "options": [
                    {
                        "value": "vero",
                        "content": "con la selezione del prodotto<br><ul><li>Crea l'ordine di acquisto</li><li>Selezione del prodotto</li><li>Approvazione</li><li>Ordine</li><li>Ricevimento della merce</li></ul>"
                    },
                    {
                        "value": "falso",
                        "content": "senza selezione del prodotto<br><ul><li>Creazione dell'ordine di acquisto</li><li>Approvazione</li><li>Ordine</li><li>Ricevimento della merce</li></ul>"
                    }
                ]
            },
            "email-send-order": {
                "group": "Ordini",
                "label": "Ordine via e-mail",
                "hint": "Messaggio e-mail generato durante la fase di inserimento dell'ordine, che include il PDF dell'ordine, e inviato al fornitore.",
                "default_value": "Gentile Signore/Signora,<br>Le invio il nostro ordine in allegato PDF. La prego di fornirci una conferma dell'ordine.",
                "tour": [
                    {
                        "target": ".step-email-order-free-text",
                        "content": "Componga qui un testo che sarà incluso nel messaggio e-mail generato durante la fase di inserimento dell'ordine, compreso il PDF dell'ordine, e inviato al fornitore."
                    },
                    {
                        "target": ".step-pdf-free-text",
                        "content": "Componga qui un testo che sarà visualizzato sul PDF dell'ordine generato durante la fase di inserimento dell'ordine, che sarà successivamente inviato al fornitore."
                    }
                ]
            },
            "procurement-pdf-free-text": {
                "group": "Ordini",
                "label": "Ordine di acquisto PDF",
                "hint": "Messaggio visualizzato sul PDF dell'ordine di acquisto generato durante la fase di inserimento dell'ordine, che sarà successivamente inviato al fornitore.",
                "default_value": ""
            },
            "invoice-recognition-lines": {
                "group": "Fatture",
                "label": "Prendi automaticamente le voci che il riconoscimento documento ha identificato."
            },
            "invoice-procurement-required": {
                "group": "Fatture",
                "label": "Richiesta di acquisto di collegamento obbligatoria."
            },
            "invoice-approval": {
                "group": "Fatture",
                "label": "Quando deve essere approvata una fattura?",
                "options": [
                    {
                        "value": "always",
                        "content": "<b>Sempre</b><br>Le fatture devono sempre essere approvate."
                    },
                    {
                        "value": "if-no-order",
                        "content": "<b>Solo se nessun ordine è assegnato</b><br>L'approvazione è richiesta solo se non è presente un ordine di acquisto (PO)."
                    },
                    {
                        "value": "never",
                        "content": "<b>Mai</b><br>Le fatture non richiedono un'approvazione separata."
                    }
                ]
            },
            "notifications-mail-interval": {
                "group": "Notifiche",
                "label": "Intervallo e-mail in minuti",
                "tour": [
                    {
                        "target": ".step-notification",
                        "content": "Imposta una frequenza globale per le notifiche via e-mail sui nuovi compiti. Le notifiche via e-mail vengono inviate solo quando una persona riceve un nuovo compito."
                    }
                ]
            },
            "product-default-tax": {
                "group": "Articolo",
                "label": "Aliquota fiscale standard (%)"
            },
            "product-default-currency": {
                "group": "Articolo",
                "label": "Valuta predefinita"
            },
            "product-default-unit": {
                "group": "Articolo",
                "label": "Unità predefinita"
            },
            "punchout-amazon-identity": {
                "group": "Integrazioni",
                "subgroup": "Punzonatura Amazon",
                "label": "Identità",
                "tour": [
                    {
                        "target": ".step-punchout-amazon",
                        "content": "Qui può impostare il suo accesso ai marketplace integrati come Amazon e Mercateo. Le istruzioni si trovano <a href=\"https://liftbase.de/handbuch/einrichtung/punch-out\" target=\"_blank\">nel nostro manuale</a>."
                    }
                ]
            },
            "punchout-amazon-secret": {
                "group": "Integrazioni",
                "subgroup": "Punzonatura Amazon",
                "label": "Segreto"
            },
            "punchout-amazon-url": {
                "group": "Integrazioni",
                "subgroup": "Punzonatura Amazon",
                "label": "URL del sistema integrato"
            },
            "punchout-amazon-order-url": {
                "group": "Integrazioni",
                "subgroup": "Punzonatura Amazon",
                "label": "URL dell'ordine"
            },
            "punchout-amazon-email": {
                "group": "Integrazioni",
                "subgroup": "Punzonatura Amazon",
                "label": "Email",
                "hint": "L'indirizzo e-mail dell'account Amazon principale. <br>Lasciare vuoto per trasmettere l'indirizzo e-mail della persona collegata. <br>Può richiedere un account Amazon per persona."
            },
            "punchout-amazon-supplier": {
                "group": "Integrazioni",
                "subgroup": "Punzonatura Amazon",
                "label": "Fornitore",
                "hint": "A quale fornitore devono essere assegnati gli ordini di questo fornitore di Punch-out (ad esempio, nei rapporti)?"
            },
            "punchout-mercateo-identity": {
                "group": "Integrazioni",
                "subgroup": "Punch-out Mercateo",
                "label": "Identità"
            },
            "punchout-mercateo-secret": {
                "group": "Integrazioni",
                "subgroup": "Punch-out Mercateo",
                "label": "Segreto"
            },
            "punchout-mercateo-url": {
                "group": "Integrazioni",
                "subgroup": "Punch-out Mercateo",
                "label": "URL del sistema integrato"
            },
            "punchout-mercateo-order-url": {
                "group": "Integrazioni",
                "subgroup": "Punch-out Mercateo",
                "label": "URL dell'ordine"
            },
            "punchout-mercateo-email": {
                "group": "Integrazioni",
                "subgroup": "Punch-out Mercateo",
                "label": "Email",
                "hint": "L'indirizzo e-mail dell'account Mercateo principale. <br>Lasciare vuoto per trasmettere l'indirizzo e-mail della persona collegata. <br>Può richiedere un account Mercateo per persona."
            },
            "punchout-mercateo-supplier": {
                "group": "Integrazioni",
                "subgroup": "Punch-out Mercateo",
                "label": "Fornitore",
                "hint": "A quale fornitore devono essere assegnati gli ordini di questo fornitore di Punch-out (ad esempio, nei rapporti)?"
            },
            "punchout-rajapack-identity": {
                "group": "Integrazioni",
                "subgroup": "Punzonatura RAJA",
                "label": "Identità"
            },
            "punchout-rajapack-secret": {
                "group": "Integrazioni",
                "subgroup": "Punzonatura RAJA",
                "label": "Segreto"
            },
            "punchout-rajapack-url": {
                "group": "Integrazioni",
                "subgroup": "Punzonatura RAJA",
                "label": "URL del sistema integrato"
            },
            "punchout-rajapack-order-url": {
                "group": "Integrazioni",
                "subgroup": "Punzonatura RAJA",
                "label": "URL dell'ordine"
            },
            "punchout-rajapack-email": {
                "group": "Integrazioni",
                "subgroup": "Punzonatura RAJA",
                "label": "Email"
            },
            "punchout-rajapack-supplier": {
                "group": "Integrazioni",
                "subgroup": "Punzonatura RAJA",
                "label": "Fornitore",
                "hint": "A quale fornitore devono essere assegnati gli ordini di questo fornitore di Punch-out (ad esempio, nei rapporti)?"
            },
            "punchout-conrad-identity": {
                "group": "Integrazioni",
                "subgroup": "Punzonatura Conrad",
                "label": "Identità"
            },
            "punchout-conrad-secret": {
                "group": "Integrazioni",
                "subgroup": "Punzonatura Conrad",
                "label": "Segreto"
            },
            "punchout-conrad-url": {
                "group": "Integrazioni",
                "subgroup": "Punzonatura Conrad",
                "label": "URL del sistema integrato"
            },
            "punchout-conrad-order-url": {
                "group": "Integrazioni",
                "subgroup": "Punzonatura Conrad",
                "label": "URL dell'ordine"
            },
            "punchout-conrad-email": {
                "group": "Integrazioni",
                "subgroup": "Punzonatura Conrad",
                "label": "Email"
            },
            "punchout-conrad-supplier": {
                "group": "Integrazioni",
                "subgroup": "Punzonatura Conrad",
                "label": "Fornitore",
                "hint": "A quale fornitore devono essere assegnati gli ordini di questo fornitore di Punch-out (ad esempio, nei rapporti)?"
            },
            "punchout-thgeyer-identity": {
                "group": "Integrazioni",
                "subgroup": "Punzonatura TH.GEYER",
                "label": "Identità"
            },
            "punchout-thgeyer-secret": {
                "group": "Integrazioni",
                "subgroup": "Punzonatura TH.GEYER",
                "label": "Segreto"
            },
            "punchout-thgeyer-url": {
                "group": "Integrazioni",
                "subgroup": "Punzonatura TH.GEYER",
                "label": "URL del sistema integrato"
            },
            "punchout-thgeyer-supplier": {
                "group": "Integrazioni",
                "subgroup": "Punzonatura TH.GEYERTH.GEYER",
                "label": "Azienda fornitrice predefinita",
                "hint": "A quale fornitore devono essere assegnati gli ordini di questo fornitore di Punch-out (ad esempio, nei rapporti)?"
            },

            "punchout-reichelt-identity": {
                "group": "Integrazioni",
                "subgroup": "Punzonatura Reichelt",
                "label": "Identità"
            },
            "punchout-reichelt-secret": {
                "group": "Integrazioni",
                "subgroup": "Punzonatura Reichelt",
                "label": "Segreto"
            },
            "punchout-reichelt-url": {
                "group": "Integrazioni",
                "subgroup": "Punzonatura Reichelt",
                "label": "URL del sistema integrato"
            },
            "punchout-reichelt-supplier": {
                "group": "Integrazioni",
                "subgroup": "Punzonatura Reichelt",
                "label": "Azienda fornitrice predefinita",
                "hint": "A quale fornitore devono essere assegnati gli ordini di questo fornitore di Punch-out (ad esempio, nei rapporti)?"
            },
            "tour": [
                {
                    "target": "",
                    "content": ""
                }
            ]
        },
        "tour": {
            "tab_general": "Qui può indicare il nome della sua azienda per l'ambiente liftbase.",
            "tab_contact": "Inserisca qui le informazioni di contatto della sua azienda. Questi dati saranno utilizzati per l'invio e la consegna delle fatture per l'utilizzo di liftbase.",
            "tab_bank": "Qui può impostare un addebito diretto SEPA per la fatturazione di liftbase o revocarlo.",
            "tab_datev": "Qui può impostare l'integrazione con DATEV. Innanzitutto, deve accedere a DATEV per caricare tutti i clienti disponibili. Poi, può collegare questi clienti con le aziende registrate qui in liftbase. Successivamente, deve collegare individualmente ogni cliente con DATEV per trasmettere le esportazioni per loro. Questa connessione ha un periodo di validità di 2 anni e si estende ogni volta che viene utilizzata per un'esportazione.",
            "tab_marketplaces": "Qui puoi configurare il tuo accesso ai marketplace integrati come Amazon e Mercateo. Puoi trovare una guida <a href=\"https://liftbase.de/handbuch/einrichtung/punch-out\" target=\"_blank\">nel nostro manuale</a>."
        }
    },
    "error_title": "Errore",
    "request_error": "Errore nell'esecuzione della richiesta. Controlli la sua connessione internet o riprovi più tardi.",
    "saved_success": "Il documento è stato salvato con successo.",
    "save_error": "Errore nel salvataggio del documento.",
    "help_page": {
        "title": "Aiuto",
        "popup_feedback_msg": "Il suo browser è impostato correttamente. Ora può chiudere questa finestra.",
        "punchout_error_msg": "Si è verificato un errore. Controlli le impostazioni di Punch-out o contatti una persona con diritti di amministrazione.",
        "popup_did_not_open": "Se non si apre una nuova finestra, i pop-up sono bloccati nel suo browser. Pertanto, potrebbero verificarsi delle limitazioni durante l'utilizzo.",
        "test_email_msg": "Dovrebbe essersi aperto un programma di posta elettronica o un elenco di programmi di posta elettronica. Se necessario, imposti un programma e-mail dall'elenco.",
        "tour_restart": "Il tour è stato riavviato.",
        "manual_title": "Manuale",
        "manual_msg": "<p>Nel manuale, troverà diversi articoli e guide sull'utilizzo e la configurazione di liftbase.</p>",
        "manual_open_btn": "Manuale aperto",
        "function_title": "Funzioni di test",
        "popup_msg": "<p>Qui può verificare se nel suo browser sono consentite le finestre pop-up, che sono necessarie per la selezione dei prodotti sui marketplace esterni come Amazon e Mercateo.</p>",
        "popup_test_btn": "Prova il blocco dei pop-up",
        "email_msg": "<p>Qui può verificare se è in grado di aprire le e-mail con un programma di posta elettronica. La funzione e-mail viene utilizzata, ad esempio, per inviare gli ordini alle aziende di consegna.</p>",
        "email_test_btn": "Prova la funzione e-mail",
        "tour_title": "Tour",
        "tour_msg": "<p>Per avere una reintroduzione a liftbase, può ricominciare il tour da qui.</p>",
        "tour_btn": "Tour di riavvio"
    },
    "dashboard_page": {
        "title": "Dashboard",
        "no_option": "Nessuna",
        "date_picker": {
            "start_placeholder": "Data di inizio",
            "end_placeholder": "Data di inizio",
            "separator": "a"
        },
        "ordered_by": "Ordinato da",
        "count_by_status": "Conteggio per stato",
        "diagrams": {
            "title": "Grafici",
            "count_by_status": "Conteggio per stato",
            "value_by_status": "Valori per stato (netti in euro)",
            "count_by_department": "Conteggio per reparto",
            "value_by_department": "Valori per reparto (netti in euro)",
            "count_by_cost_center": "Conteggio per centro di costo",
            "value_by_cost_center": "Valori per centro di costo (netto in EUR)",
            "count_by_ledger_account": "Numero per conto contabile",
            "value_by_ledger_account": "Importi per conto contabile (netto in EUR)"
        },
        "budgets": {
            "title": "Bilanci e spese",
            "approved_status": "Approvato",
            "in_approval_status": "In Approvazione",
            "open_status": "Aperto",
            "no_budget": "Senza budget",
            "from": "Da",
            "no_name": "Senza nome"
        },
        "products": {
            "title": "Prodotti",
            "approved_status": "Approvato",
            "in_approval_status": "In Approvazione"
        },
        "no_data": "Non sono stati trovati ordini di acquisto.",
        "no_permission": "Non ha l'autorizzazione per il dashboard.",
        "from_budget": "dal budget",
        "data": "Spese",
        "shortcuts": {
            "this_week": "Questa settimana",
            "last_week": "La scorsa settimana",
            "this_month": "Questo mese",
            "last_month": "Il mese scorso",
            "last_3_months": "Ultimi 3 mesi",
            "last_6_months": "Ultimi 6 mesi",
            "this_year": "Quest'anno",
            "last_year": "L'anno scorso"
        },
        "tour": {
            "title": "Il dashboard le offre statistiche e funzioni di valutazione degli ordini di acquisto.",
            "filters_title": "Filtri",
            "filters": "Per prima cosa, selezioni il periodo desiderato e poi filtri gli ordini di acquisto per azienda, ordine per, centro di costo o reparto.",
            "count_by_status_title": "Conteggio per stato",
            "count_by_status": "Questo mostra quanti ordini di acquisto pre-filtrati si trovano attualmente nei rispettivi stati.",
            "graphic_title": "Grafici",
            "graphic": "Si tratta di una rappresentazione grafica del numero o degli importi degli ordini di acquisto, raggruppati per stato, reparti o centri di costo.",
            "budget_title": "Bilanci e spese",
            "budget": "Quest'area le offre una panoramica dei budget e delle spese dei suoi centri di costo. Le barre colorate mostrano l'utilizzo dei budget e quante spese sono ancora in fase di approvazione.",
            "progress_bar_cost_center_title": "Barre di avanzamento per centro di costo",
            "progress_bar_cost_center": "Qui può aprire una panoramica più dettagliata dei diversi budget di un centro di costo. Gli ordini effettuati possono essere elencati anche per i singoli budget. Le spese senza budget sono elencate in una barra separata."
        }
    },
    "overview_page": {
        "title": "Panoramica",
        "procurement_abbr": "PO",
        "invoice_abbr": "IN",
        "draft_abbr": "DR",
        "delivery_note_abbr": "DN",
        "all_docs": "Tutti i documenti",
        "my_tasks": "I miei compiti",
        "procurements": "Ordini di acquisto",
        "invoices": "Fatture",
        "drafts": "Bozze",
        "no_selection": "Non è stato selezionato alcun documento.",
        "comment_box_placeholder": "Il suo commento...",
        "comment_box_btn": "Come",
        "edit_filename_title": "Modifica nome file",
        "edit_filename_msg": "Si prega di inserire il nuovo nome del file. <br> Nome precedente: {filename}",
        "duplicate_invoice": {
            "message": "Duplicato - È stata trovata almeno una fattura con lo stesso numero di fattura:",
            "invoice_number": "Numero di fattura",
            "invoice_date": "Data"
        },
        "task_list": {
            "name": "Nome",
            "status": "Stato",
            "date": "Data",
            "draft": "Bozza"
        },
        "searchbar": {
            "search": "Trova",
            "filter_by": "Filtrare per",
            "created_at": "Data di creazione",
            "timeline": "Timeline",
            "last_used": "Ultimo utilizzo",
            "no_search": "Nessuno",
            "timeline_props": {
                "editor_name": "Editor",
                "entry_type": "Fase della cronologia",
                "current_editor_name": "Editor attuale",
                "current_entry_type": "Fase attuale"
            },
            "procurement_props": {
                "order_number_formatted": "Numero d'ordine",
                "name": "Nome",
                "creator_name": "Creato da",
                "client_name": "Azienda",
                "department_name": "Reparto",
                "cost_center_name": "Nome del centro di costo",
                "cost_center_number": "Numero del centro di costo",
                "consignee_name": "Ricevimento della merce",
                "status": "Stato",
                "product_names": "Prodotto",
                "product_numbers": "Numero del prodotto",
                "supplier_names": "Fornitore"
            },
            "invoice_props": {
                "invoice_number": "Numero di fattura",
                "invoice_number_formatted": "Numero interno LB",
                "procurement_number": "Numero d'ordine",
                "creator_name": "Caricato da",
                "client_name": "Azienda",
                "cost_center_name": "Nome del centro di costo",
                "cost_center_number": "Numero del centro di costo",
                "supplier_name": "Fornitore",
                "ledger_account_name": "Conto spese",
                "status": "Stato",
                "product_names": "Prodotto",
                "product_numbers": "Numero del prodotto",
                "due_date": "Scadenza"
            },
            "time_ranges": {
                "today": "oggi",
                "yesterday": "ieri",
                "past_7_days": "ultimi 7 giorni",
                "past_30_days": "ultimi 30 giorni",
                "this_week": "questa settimana",
                "last_week": "la scorsa settimana",
                "this_month": "questo mese",
                "last_month": "il mese scorso",
                "this_year": "quest'anno",
                "last_year": "l'anno scorso"
            }
        },
        "export": {
            "all_information": "Tutte le informazioni",
            "export_procurement": "Esportazione di ordini di acquisto",
            "export_documents": "Esportazione di documenti",
            "registers": "voci",
            "export": "Esportazione",
            "to_export": "Esporta",
            "export_with_products": "Esportazione con prodotti",
            "custom_export": "Esportazione configurata",
            "select_fields": "Seleziona i campi che devono essere esportati.",
            "select_all": "Seleziona tutti",
            "deselect_all": "Rimuovi tutto",
            "general": "Generale",
            "products": "Prodotti",
            "include_product": "Prodotti inclusi",
            "filter": " filtro",
            "no_filter": "no filter",
            "labels": {
                "procurement": {
                    "order_number_formatted": "Numero LB",
                    "name": "Nome",
                    "creator_name": "Creato da",
                    "status": "Stato",
                    "total_price_brutto": "Totale grande",
                    "total_price_netto": "Totale-Netto",
                    "type_total_price_brutto": "currency",
                    "type_total_price_netto": "currency",
                    "created_at": "Data di creazione",
                    "client_number": "Numero dell'azienda",
                    "client_name": "Nome dell'azienda",
                    "department_name": "Reparto",
                    "cost_center_number": "Numero del centro di costo",
                    "cost_center_name": "Nome del centro di costo",
                    "consignee_name": "Ricevimento della merce",
                    "shipping_date": "Data di consegna",
                    "shipping_name": "Nome dell'indirizzo di consegna",
                    "shipping_consignee": "Indirizzo di consegna nome del destinatario",
                    "shipping_dropoff_location": "Indirizzo di consegna Nome del luogo di consegna",
                    "shipping_address": "Indirizzo di consegna via e numero civico.",
                    "shipping_complement": "Indirizzo di consegna via 2",
                    "shipping_country": "Indirizzo di consegna paese",
                    "shipping_zipcode": "Indirizzo di consegna codice postale",
                    "shipping_city": "Indirizzo di consegna città",
                    "shipping_phone_country_code": "Indirizzo di consegna prefisso telefonico del paese",
                    "shipping_phone_number": "Indirizzo di consegna numero di telefono",
                    "billing_name": "Nome dell'Indirizzo di fatturazione",
                    "billing_consignee": "Indirizzo di fatturazione nome del destinatario",
                    "billing_dropoff_location": "Indirizzo di fatturazione nome del luogo di consegna",
                    "billing_address": "Indirizzo di fatturazione via e n.",
                    "billing_complement": "Indirizzo di fatturazione via 2",
                    "billing_country": "Indirizzo di fatturazione Paese",
                    "billing_zipcode": "Indirizzo di fatturazione codice postale",
                    "billing_city": "Indirizzo di fatturazione città",
                    "billing_phone_country_code": "Indirizzo di fatturazione prefisso telefonico del paese",
                    "billing_phone_number": "Indirizzo di fatturazione numero di telefono"
                },
                "product": {
                    "product_name": "Nome del prodotto",
                    "product_number": "Numero dell'articolo",
                    "product_supplier_name": "Nome del fornitore",
                    "product_supplier_email": "Email del fornitore",
                    "product_cost_center_name": "Nome del centro di costo (prodotto)",
                    "product_cost_center_number": "Numero del centro di costo (prodotto)",
                    "product_tax": "Imposta",
                    "product_quantity": "Quantità (prodotto)",
                    "product_total_netto": "Totale-netto (prodotto)",
                    "product_total_brutto": "Totale-lordo (prodotto)",
                    "product_netto": "Single price net",
                    "product_brutto": "Prezzo unico lordo",
                    "product_unit": "Unità",
                    "product_img_url": "URL immagine",
                    "product_shop_url": "URL del negozio",
                    "product_shipping_name": "Nome dell'indirizzo di consegna (prodotto)",
                    "product_shipping_consignee": "Indirizzo di consegna nome del destinatario (prodotto)",
                    "product_shipping_dropoff_location": "Indirizzo di consegna Nome del luogo di consegna (pProdotto)",
                    "product_shipping_address": "Indirizzo di consegna via e n. (prodotto)",
                    "product_shipping_complement": "Indirizzo di consegna via 2 (prodotto)",
                    "product_shipping_country": "Indirizzo di consegna Paese (prodotto)",
                    "product_shipping_zipcode": "Indirizzo di consegna codice postale (prodotto)",
                    "product_shipping_city": "Indirizzo di consegna città (prodotto)",
                    "product_shipping_phone_country_code": "Indirizzo di consegna prefisso telefonico del paese (prodotto)",
                    "product_shipping_phone_number": "Indirizzo di consegna numero di telefono (prodotto)",
                    "product_received_at": "Ultimo ricevimento merci (data)",
                    "product_received_by": "Ultimo ricevimento merci (persona)"
                },
                "invoice": {
                    "invoice_number_formatted": "Numero di fattura interna",
                    "number": "Numero di fattura",
                    "creator_name": "Caricato da",
                    "status": "Stato",
                    "procurement_name": "Ordine di acquisto",
                    "procurement_number": "Numero d'ordine",
                    "cost_center_name": "Nome del centro di costo",
                    "cost_center_number": "Numero del centro di costo",
                    "total_price_netto": "Totale netto",
                    "total_price_brutto": "Totale lordo",
                    "type_total_price_brutto": "currency",
                    "type_total_price_netto": "currency",
                    "created_at": "Data caricamento",
                    "client_name": "Azienda",
                    "client_number": "Numero dell'azienda",
                    "invoice_date": "Data fattura",
                    "due_date": "Scadenza",
                    "delivery_date": "Data di consegna",
                    "currency": "Valuta",
                    "ledger_account_name": "Nome del conto contabile",
                    "ledger_account_number": "Numero di conto contabile",
                    "posting_key_number": "Numero posting key",
                    "posting_key_tax": "Aliquota fiscale posting key",
                    "supplier_name": "Fornitore"
                }
            }
        },
        "actions": {
            "tour": {
                "procurement_reassignment_title": "Assegnazione/Chiarimento dell'ordine di acquisto",
                "procurement_reassignment_msg": "Questo compito è attualmente affidato ad un'altra persona. Utilizzi questo pulsante per inviare l'attività per chiarimenti o per assegnarla a una terza persona o a se stesso. In fase di chiarimento, gli ordini di acquisto possono anche essere cancellati.",
                "invoice_reassignment_title": "Assegnazione/Chiarimento della fattura",
                "invoice_reassignment_msg": "Questo compito è attualmente affidato ad un'altra persona. Utilizzi questo pulsante per inviare l'attività per chiarimenti o per assegnarla a una terza persona o a se stesso. In fase di chiarimento, è possibile cancellare anche le fatture."
            },
            "skip_confirm_dialog_title": "Annulla il riconoscimento dei documenti",
            "skip_confirm_dialog_msg": "Per verificare se esiste già un risultato del riconoscimento del documento, aggiorna la pagina nel browser. <br>Sei sicuro di voler annullare il riconoscimento dei dati della fattura e inserire manualmente i dati della fattura?",
            "button_hint": {
                "to_approval": "All'approvazione",
                "to_send_order": "Per ordinare",
                "to_accounting": "Alla contabilità",
                "approve": "Approvare",
                "self_assign": "Accettare",
                "finish_order": "Ordine completo",
                "finish_delivery": "Finitura",
                "skip_recognition": "Annulla il riconoscimento dei documenti",
                "to_export": "Per esportare",
                "default": "Continua",
                "cancel_or_assign_procurement": "Assegnazione/Annullamento",
                "cancel_or_assign_invoice": "Assegnazione/Annullamento",
                "clearing_or_assign": "Assegnazione/Chiarimento"
            },
            "assignment_tooltip": "Chiarimento o assegnazione",
            "assignment_dialog": {
                "title_procurement_assign_or_cancel": "Assegnazione o cancellazione",
                "title_invoice_assign_or_cancel": "Assegnazione o cancellazione",
                "title_assign": "Assegnazione",
                "title_clearing_or_assign": "Chiarimento o assegnazione",
                "assign_title": "Assegna il compito corrente a un'altra persona",
                "assign_msg": "La persona attualmente assegnata non è disponibile o non è responsabile di questo compito, quindi un'altra persona deve assumerlo.",
                "clearing_title": "Assegnare per chiarimenti",
                "clearing_msg": "La persona attualmente assegnata è disponibile e responsabile per questo compito, ma il processo non può essere continuato per altri motivi. Pertanto, desidera avviare un processo di chiarimento.",
                "cancel_procurement_title": "Cancellazione",
                "cancel_procurement_msg": "L'ordine è irrevocabilmente annullato e non può essere elaborato ulteriormente. Gli ordini annullati si trovano nella panoramica con lo stato 'Declinato'.",
                "cancel_invoice_title": "Annullamento",
                "cancel_invoice_msg": "La fattura viene cancellata irrevocabilmente e non può essere elaborata ulteriormente. Le fatture cancellate si trovano nella panoramica di tutti i documenti con lo stato 'Cancellato'.",
                "assign_to": "Assegnare a",
                "reason": "Motivo",
                "clearing_footer_info": "Il compito corrente viene annullato. La persona selezionata riceve un nuovo compito da chiarire.",
                "reassignment_footer_info": "Viene modificata solo la persona assegnata dell'attività corrente.",
                "cancel_invoice_btn": "Annulla la fattura",
                "cancel_procurement_btn": "Annulla l'ordine",
                "assign_btn": "Assegnazione",
                "clearing_dialog": {
                    "title": "Persona di chiarimento",
                    "procurement_message": "La persona nella fase di 'chiarimento' risulta da vari default di persone responsabili per reparto, azienda, un default di ruolo globale e la persona che ha creato l'ordine. La persona che si trova in cima all'elenco è preassegnata per il compito.",
                    "invoice_message": "La persona nella fase di 'chiarimento' risulta da varie impostazioni predefinite di persone responsabili per reparto, azienda, un ruolo globale predefinito e la persona che ha caricato la fattura. La persona che si trova in cima all'elenco è preassegnata al compito.",
                    "department_form": "Reparto in forma",
                    "department_creator": "Reparto della persona ordinata",
                    "client_form": "Azienda in forma",
                    "client_creator": "Azienda della persona ordinata",
                    "no_selection": "non ancora selezionato",
                    "no_action": "non applicabile"
                },
                "cancellation_dialog": {
                    "title_procurement": "Cancellazione",
                    "btn_procurement": "Annulla l'ordine",
                    "message_procurement": "Vuole davvero cancellare questo ordine?",
                    "title_invoice": "Annullamento",
                    "btn_invoice": "Annulla la fattura",
                    "message_invoice": "Vuole davvero cancellare questa fattura?"
                }
            }
        },
        "tour": {
            "overview": {
                "search_box": "Con la ricerca, può filtrare gli ordini di acquisto sotto \"I miei compiti\" e \"Tutti i documenti\" e cercare varie informazioni per trovare più velocemente ordini di acquisto specifici.",
                "tab_tasks": "Qui può vedere gli ordini di acquisto di cui è attualmente responsabile. Nella colonna di destra, può vedere lo stato dei suoi compiti.",
                "tab_all_procurements": "In questa struttura ad albero, può trovare tutti gli ordini di acquisto, ordinati in base allo stato attuale.",
                "refresh_btn": "Qui può aggiornare la panoramica degli ordini di acquisto.",
                "type_filter": "Qui può filtrare in base al tipo di compito."
            },
            "procurement": {
                "form_title": "Forma dell'ordine di acquisto",
                "form": "Qui può vedere le informazioni sull'ordine di acquisto.",
                "approve_btn_title": "Inoltrare l'ordine di acquisto",
                "approve_btn": "Quando ha completato un compito e desidera inoltrare l'ordine d'acquisto alla persona responsabile successiva o alla fase successiva, prema il segno di spunta blu.",
                "reassign_btn": "Se non vuole completare il compito, perché, ad esempio, ci sono ancora delle incertezze o un'altra persona deve continuare ad elaborare il compito, può premere il pulsante rosso. A questo punto può consegnare il compito o assegnarlo a qualcuno perché lo chiarisca. Durante il processo di chiarimento, gli ordini di acquisto possono anche essere cancellati.",
                "add_comment": "Può aggiungere un commento all'ordine di acquisto tramite il campo commenti. Questo viene poi visualizzato nella timeline.",
                "timeline_title": "Timeline",
                "timeline": "La timeline mostra l'andamento passato e futuro dell'ordine di acquisto, nonché lo stato attuale (in blu) e i commenti sull'ordine di acquisto. L'ordine può essere invertito utilizzando l'icona dell'ingranaggio.",
                "timeline_preferences": "Tramite questo pulsante, è possibile effettuare le impostazioni di visualizzazione della timeline. Ad esempio, si può impostare se gli eventi devono essere ordinati in ordine crescente o decrescente e se le modifiche all'ordine di acquisto devono essere nascoste o visualizzate."
            },
            "invoice": {
                "form_title": "Modulo per la fattura",
                "form": "Qui può inserire o modificare le informazioni della fattura.",
                "add_line": "Qui può aggiungere una voce di fattura.",
                "edit_lines": "Qui può regolare le informazioni sulle voci della fattura.",
                "approve_btn_title": "Inoltrare la fattura",
                "approve_btn": "Quando ha completato un compito e desidera inoltrare la fattura alla persona responsabile successiva o alla fase successiva, può premere il segno di spunta blu.",
                "reassign_btn": "Se non vuole terminare il compito, ad esempio perché c'è un problema da risolvere o se un'altra persona deve continuare a lavorare sul compito, può premere il pulsante rosso. Quindi può assegnare il compito a qualcun altro o assegnarlo a qualcuno per chiarimenti. Durante il processo di chiarimento, le fatture possono anche essere modificate.",
                "add_comment": "Può aggiungere un commento alla fattura tramite il campo commenti. Questo verrà poi visualizzato nella timeline.",
                "timeline_title": "Timeline",
                "timeline": "La timeline mostra l'andamento passato e futuro della fattura, nonché lo stato attuale (in blu) e i commenti sulla fattura. L'ordine può essere invertito utilizzando l'icona dell'ingranaggio.",
                "timeline_preferences": "Tramite questo pulsante, è possibile effettuare le impostazioni di visualizzazione della timeline. Qui si può impostare se gli eventi devono essere ordinati in ordine crescente o decrescente e se le modifiche alla fattura devono essere nascoste o visualizzate."
            },
            "welcome": {
                "logo_title": "Benvenuto su liftbase!",
                "logo": "<p>Per facilitarle l'inizio, le spiegheremo le cose più importanti con un tour attraverso liftbase.</p> <p>Utilizzi i pulsanti qui sotto o i tasti freccia per navigare nel tour.</p>",
                "menu": "Nell'angolo superiore destro si trova il menu principale, attraverso il quale può trovare tutte le aree importanti di liftbase.",
                "menu_help": "Può terminare le singole sezioni del tour in qualsiasi momento e riavviarle in un secondo momento nel menu di aiuto.",
                "menu_admin": "Qui può trovare la prossima sezione del tour."
            }
        }
    },
    "operator_area": {
        "title": "Operator",
        "tenant_menu": "Inquilini",
        "procurement_menu": "Ordini di acquisto",
        "invoice_menu": "Fatture",
        "no_permission": "Non è autorizzato a vedere quest'area.",
        "tenant": {
            "description": "Inquilino",
            "new": "Nuovo inquilino",
            "edit": "Modifica",
            "archive": "Archivio",
            "delete": "Cancellare",
            "activate": "Restore",
            "procurement_count": "PO",
            "user_count": "Utente",
            "automatic_billing": "Fatturazione automatica",
            "tabs": {
                "general": "Generale",
                "invoice_config": "Fatturazione"
            },
            "standard_package": "Pacchetto standard",
            "invoice": {
                "items": {
                    "label": "Voci aggiuntive",
                    "description": "Descrizione",
                    "price": "Importo netto",
                    "start_date": "Data di inizio",
                    "end_date": "Data di fine"
                },
                "tiered_price": {
                    "label": "Prezzi a scaglioni",
                    "unit_price": "Prezzo unitario",
                    "base_price": "Prezzo base",
                    "doc_amount": "Quantità"
                }
            },
            "contact_email_tooltip": "L'inquilino può inizialmente accedere con questa e-mail.",
            "no_bank_info": "I dati non sono ancora disponibili e possono essere creati solo dall'inquilino.",
            "no_connection_with_easybill": "Questo inquilino non è ancora collegato a Easybill. La preghiamo di registrare l'ID dell'inquilino nel sistema Easybill.",
            "sync_with_easybill": "Recuperare tutti i dati da Easybill",
            "update_from_easybill_btn": "Recupera da Easybill",
            "update_from_easybill_tooltip": "Recuperare l'inquilino da Easybill",
            "send_to_easybill_btn": "Creare in Easybill",
            "send_to_easybill_tooltip": "Creare un inquilino in Easybill",
            "create_and_send_invoice_tooltip": "Crei la fattura in Easybill e la invii al cliente.",
            "create_and_send_invoice_btn": "Creare/inviare la fattura",
            "open_invoices": "Visualizza le fatture",
            "delete_procurements": "Cancellare l'ordine di acquisto",
            "delete_invoices": "Cancellare le fatture",
            "confirm_before_delete_msg": "<p>È sicuro di voler eliminare definitivamente questo inquilino e tutti i suoi dati dal database? </p><p> <i>Questa azione non può essere annullata.</i></p><p><b>Inserisca il nome dell'inquilino:</b></p>",
            "confirm_before_delete_error_msg": "L'inquilino non è stato cancellato perché il nome è stato inserito in modo errato.",
            "deleted_msg": "L'inquilino è stato eliminato in modo permanente.",
            "updated_msg": "È stato aggiornato.",
            "created_msg": "È stato creato.",
            "sync_msg_error": "Questo inquilino non è ancora collegato a Easybill. La preghiamo di registrare l'ID dell'inquilino nel sistema Easybill.",
            "create_invoice_dialog": {
                "title": "Creare la fatturazione",
                "message": "Scelga il periodo di fatturazione.",
                "date_placeholder": "Selezioni un mese.",
                "create_btn": "Creare"
            },
            "validation": {
                "name_required": "La preghiamo di inserire un nome.",
                "name_exists": "Il nome esiste già.",
                "email_required": "Inserisca un'e-mail di contatto.",
                "email_invalid": "Inserisca un'e-mail valida."
            }
        },
        "procurement": {
            "title": "Ordini di acquisto da parte dell'affittuario",
            "delete_title": "Cancellare l'ordine di acquisto",
            "result_found": "Colpi",
            "delete_result_found": "Cancella i risultati",
            "start_date": "Data di inizio",
            "end_date": "Data di fine",
            "range_separator": "a",
            "confirm_delete_msg": "È sicuro di voler eliminare definitivamente gli ordini di acquisto selezionati dal database?",
            "confirm_delete_title": "Cancellare l'ordine di acquisto",
            "confirm_delete_btn": "Cancellare"
        },
        "invoice": {
            "title": "Fatture dell'inquilino",
            "delete_title": "Cancellare le fatture",
            "result_found": "Colpi",
            "delete_result_found": "Cancella i risultati",
            "start_date": "Data di inizio",
            "end_date": "Data di fine",
            "range_separator": "a",
            "confirm_delete_msg": "È sicuro di voler eliminare definitivamente le fatture selezionate dal database?",
            "confirm_delete_title": "Cancellare la fattura",
            "confirm_delete_btn": "Cancellare"
        }
    },
    "timeline": {
        "title": "Timeline",
        "preferences": {
            "order_asc": "Prima il più anziano",
            "order_desc": "Prima i più nuovi"
        },
        "text": {
            "description_assignment": "Il compito è stato accettato.",
            "cancellation": {
                "name": "{name}",
                "description_past_successful_invoice": "La fattura è stata rimossa e archiviata.",
                "description_past_successful_procurement": "L'ordine d'acquisto è stato annullato e archiviato.",
                "name_procurement": "Annullamento",
                "name_invoice": "Rimozione"
            },
            "start": {
                "name": "Nuovo ordine di acquisto",
                "description_past_successful": "È stato creato un nuovo ordine di acquisto."
            },
            "select-product": {
                "is_searchable": true,
                "simple_name": "Selezione del prodotto",
                "name": "Selezione del prodotto (Fase {index})",
                "description_past_unsuccessful": "La selezione del prodotto è stata annullata.",
                "description_past_successful": "I prodotti per questo ordine sono stati selezionati.",
                "description_present": "Selezioni i prodotti per questo ordine.",
                "description_future": "È necessario selezionare i prodotti per questo ordine."
            },
            "approval": {
                "is_searchable": true,
                "simple_name": "Approvazione",
                "name": "Approvazione (Fase {index})",
                "description_past_unsuccessful": "L'approvazione dell'ordine è stata annullata.",
                "description_past_successful": "L'ordine di acquisto è stato esaminato e approvato.",
                "description_present": "Si prega di rivedere e approvare l'ordine di acquisto.",
                "description_future": "L'ordine di acquisto deve essere rivisto e approvato."
            },
            "send-order": {
                "is_searchable": true,
                "name": "Ordinazione di ordini",
                "description_past_unsuccessful": "L'ordine è stato annullato.",
                "description_past_successful": "L'ordine è stato effettuato al fornitore.",
                "description_present": "La preghiamo di effettuare l'ordine al fornitore.",
                "description_future": "L'ordine deve essere effettuato al fornitore."
            },
            "delivery": {
                "is_searchable": true,
                "name": "Conferma di consegna",
                "description_past_unsuccessful": "La conferma di ricezione della merce è stata annullata.",
                "description_past_successful": "La merce è stata consegnata e il ricevimento è stato confermato.",
                "description_present": "La preghiamo di confermare la ricezione della merce.",
                "description_future": "Il ricevimento della merce deve essere confermato."
            },
            "reassignment": {
                "name": "Assegnazione",
                "description_past_unsuccessful": "",
                "description_past_successful": "ha assegnato il compito a {nome}.",
                "description_past_successful_from_group": "ha accettato il compito."
            },
            "clearing": {
                "is_searchable": true,
                "name": "Chiarimento",
                "description_past_unsuccessful": "Il chiarimento è stato annullato.",
                "description_past_successful": "Le incertezze sono state eliminate.",
                "description_present": "La preghiamo di chiarire le incertezze relative all'ordine.",
                "description_finished_unsuccessful": "L'ordine è stato cancellato e archiviato."
            },
            "order-sent-internal": {
                "name": "Messaggio del sistema",
                "description_past_successful": "L'ordine <b>({first_product_name})</b> con il fornitore <b>{supplier}</b> è stato effettuato e confermato."
            },
            "order-sent-punchout": {
                "name": "Messaggio del sistema",
                "description_past_successful": "L'ordine <b>({first_product_name})</b> è stato inviato elettronicamente al fornitore <b>{supplier}</b>."
            },
            "product-received": {
                "name": "Messaggio del sistema",
                "description_past_successful": "<b>{quantity} {unit}</b> ricevuta:<p>{product}</p><span style=\"color:#9a9898\">{supplier}</span>"
            },
            "invoice-upload": {
                "name": "Nuova fattura",
                "description_past_successful": "La fattura è stata caricata."
            },
            "invoice-recognition": {
                "is_searchable": true,
                "name": "Riconoscimento delle fatture",
                "description_past_unsuccessful": "Il riconoscimento dei dati della fattura è stato annullato.",
                "description_past_successful": "Il riconoscimento dei dati delle fatture è stato completato.",
                "description_present": "I dati della fattura vengono letti. Questo processo può richiedere fino a un minuto. Si prega di ricaricare la pagina dopo questo tempo per vedere i risultati."
            },
            "invoice-manual-check": {
                "is_searchable": true,
                "name": "Controllo manuale",
                "description_past_unsuccessful": "Il controllo manuale non è stato completato.",
                "description_past_successful": "La fattura è stata controllata manualmente.",
                "description_present": "La preghiamo di controllare i dati della fattura.",
                "description_future": "La fattura deve essere controllata manualmente."
            },
            "invoice-financial-approval": {
                "is_searchable": true,
                "simple_name": "Approvazione Fattura",
                "name": "Approvazione Fattura (Fase {index})",
                "description_past_unsuccessful": "L'approvazione della fattura è stata annullata.",
                "description_past_successful": "La fattura è stata verificata e approvata.",
                "description_present": "Si prega di verificare e approvare la fattura.",
                "description_future": "La fattura deve essere verificata e approvata."
            },
            "invoice-clearing": {
                "is_searchable": true,
                "name": "Chiarimento della contabilità",
                "description_past_unsuccessful": "Il chiarimento è stato annullato.",
                "description_past_successful": "Le incertezze sulla fattura sono state eliminate.",
                "description_present": "La preghiamo di chiarire le incertezze relative alla fattura.",
                "description_finished_unsuccessful": "La fattura è stata annullata e archiviata."
            },
            "invoice-export": {
                "is_searchable": true,
                "name": "Esportazione",
                "description_past_unsuccessful": "La fattura non è stata esportata.",
                "description_past_successful": "La fattura è stata presentata a {export_name}.",
                "description_present": "La fattura è pronta per l'esportazione.",
                "description_future": "La fattura è pronta per l'esportazione."
            },
            "invoice-accounting": {
                "is_searchable": true,
                "name": "Contabilità",
                "description_past_unsuccessful": "La contabilità è stata interrotta.",
                "description_past_successful": "La contabilità è stata verificata.",
                "description_present": "Si prega di controllare la contabilità.",
                "description_future": "La contabilità deve essere controllata."
            },
            "skip": {
                "name": "Cancellazione",
                "description_past_successful": "ha annullato il passo."
            }
        }
    },
    "jexcel": {
        "noRecordsFound": "Non sono state trovate voci.",
        "showingPage": "Pagina {0} di {1}",
        "show": "Mostra",
        "entries": " voci",
        "insertANewColumnBefore": "Inserisca la colonna prima",
        "insertANewColumnAfter": "Inserisca la colonna dopo",
        "deleteSelectedColumns": "Cancelli le colonne selezionate",
        "renameThisColumn": "Rinomini questa colonna",
        "orderAscending": "Ordinamento ascendente",
        "orderDescending": "Ordinamento discendente",
        "insertANewRowBefore": "Inserisca la riga prima di",
        "insertANewRowAfter": "Inserisca la riga dopo",
        "deleteSelectedRows": "Cancellare le righe selezionate",
        "copy": "Copia",
        "paste": "Incolla",
        "saveAs": "Salva con nome",
        "about": "A proposito di",
        "areYouSureToDeleteTheSelectedRows": "È sicuro di voler eliminare le righe selezionate?",
        "areYouSureToDeleteTheSelectedColumns": "È sicuro di voler eliminare le colonne selezionate?",
        "thisActionWillDestroyAnyExistingMergedCellsAreYouSure": "Questa azione distruggerà tutte le celle unite esistenti. È sicuro?",
        "thisActionWillClearYourSearchResultsAreYouSure": "Questa azione cancellerà i risultati della ricerca. È sicuro?",
        "thereIsAConflictWithAnotherMergedCell": "C'è un conflitto con un'altra cella unita",
        "invalidMergeProperties": "Proprietà unite non valide",
        "cellAlreadyMerged": "Cella già unita",
        "noCellsSelected": "Nessuna cella selezionata",
        "save_warning": "Copiare dati tra Excel e il web può portare a sequenze errate e alla perdita di relazioni ID. <br>Si prega di utilizzare solo la modifica online o di aggiungere i dati di Excel come supplemento."
    },
    "system_comment": {
        "name": {
            "allocation": "Assegnazione",
            "datev_export": "Esportazione DATEV",
            "export_error": "Errore di esportazione",
            "export_detail": "Protocollo di esportazione",
            "repeat_recognition": "Ripeti riconoscimento fattura"
        },
        "recognition_was_repeated": "{user_name} ha ripetuto il riconoscimento documento.",
        "attach_new_invoice_to_procurement": "La fattura <b>{link_to_invoice}</b> di <b>{supplier_name}</b> è stata assegnata a questo ordine d'acquisto da <b>{user_name}</b>.",
        "attach_new_invoice_to_procurement_without_supplier": "La fattura <b>{link_to_invoice}</b> è stata assegnata a questo ordine d'acquisto da <b>{user_name}</b>.",
        "change_procurement_of_invoice": "L'assegnazione della fattura <b>{link_to_invoice}</b> da <b>{supplier_name}</b> è stata rimossa. <br>La fattura è stata assegnata all'ordine di acquisto {link_to_procurement}.",
        "change_procurement_of_invoice_without_supplier": "L'assegnazione della fattura <b>{link_to_invoice}</b> è stata rimossa. <br>La fattura è stata assegnata all'ordine di acquisto {link_to_procurement}.",
        "detach_procurement_of_invoice": "L'assegnazione della fattura <b>{link_to_invoice}</b> da <b>{supplier_name}</b> è stata rimossa.",
        "detach_procurement_of_invoice_without_supplier": "L'assegnazione della fattura <b>{link_to_invoice}</b> è stata rimossa.",
        "attach_external_invoice_to_procurement": "La fattura {invoice_number} di <b>{supplier_name}</b> è stata assegnata a questo ordine d'acquisto da <b>{invoice_by}</b>.",
        "insert_datev_note": "La fattura <b>{link_to_invoice}</b> di <b>{supplier_name}</b> è stata inviata a DATEV.",
        "insert_datev_note_without_supplier": "La fattura <b>{link_to_invoice}</b> è stata inviata a DATEV.",
        "on_ms365_export_error_generic": "Errore durante l'esportazione della fattura in D365. Contatti l'assistenza.",
        "on_ms365_export_validation_error": "Errore durante l'esportazione della fattura in D365. Contatti l'assistenza. Errore: {error}",
        "on_export_error": "Non è stato possibile inviare la fattura a DATEV. {error}",
        "on_export_error_generic": "Errore durante l'esportazione della fattura in DATEV. Contatti l'assistenza.",
        "on_export_error_validation": "{supplier_external_id_export_validation}{supplier_name_export_validation} Si rivolga a qualcuno con diritti di amministrazione per verificare le impostazioni.",
        "on_export_error_datev_access_is_not_active": "La connessione con DATEV deve essere stabilita. Si rivolga a qualcuno con diritti di amministratore.",
        "on_export_error_client_has_no_datev_access": "L'azienda {client_name} non ha memorizzato alcun dato di accesso DATEV. La preghiamo di contattare qualcuno con i diritti di amministratore.",
        "on_export_error_client_has_no_datev_permission": "Si è verificato un errore durante la trasmissione a DATEV. L'account DATEV impostato non ha i permessi per l'azienda {client_name}. La preghiamo di contattare una persona con diritti di amministratore per verificare l'accesso, i dati aziendali o le autorizzazioni all'interno di DATEV.",
        "on_export_error_datev_has_protocol_entries": "Errore durante l'esportazione della fattura su DATEV. Si prega di controllare il registro di esportazione o contattare l'assistenza.",
        "on_export_error_no_fiscal_year": "Si è verificato un errore durante la trasmissione a DATEV. La data della fattura non rientra nell'anno fiscale di DATEV. Si rivolga a qualcuno con diritti di amministratore per verificare l'anno fiscale in DATEV.",
        "on_export_error_invoice_not_active_for_fiscal_year": "Si è verificato un errore durante la trasmissione a DATEV. L'anno fiscale di DATEV non è stato rilasciato per la trasmissione delle fatture in entrata. Si rivolga a qualcuno con diritti di amministratore per verificare l'anno fiscale in DATEV.",
        "insert_datev_protocol_note": "<b>Stato</b>: {status} <br><br> <b>Voci di protocollo</b>: <br> {protocol}",
        "labels": {
            "invoice_number_label": "Numero di fattura",
            "procurement_number_label": "Numero d'ordine",
            "invoice_date_label": "Data della fattura",
            "invoice_due_date_label": "Scadenza",
            "supplier_label": "Fornitore",
            "client_label": "Azienda",
            "total_netto_label": "Totale netto",
            "total_brutto_label": "Totale lordo",
            "tax_label": "Aliquota fiscale",
            "positions_count_label": "Posizione/i",
            "no_data_read_label": "Nessun dato riconosciuto.",
            "supplier_external_id_export_validation": "L'ID esterno del fornitore deve essere specificato nell'area di amministrazione.",
            "supplier_name_export_validation": "Il nome del fornitore deve essere specificato nell'area di amministrazione.",
            "recognition_was_repeated": "{user_name} ha ripetuto il riconoscimento documento.",
        }
    },
    "login": {
        "email": {
            "title": "Acceda con l'e-mail",
            "instructions": "Inserisca il suo indirizzo e-mail. Poi clicchi sul link di accesso contenuto nell'e-mail.",
            "punchin_instructions": "Per completare il processo, deve effettuare il login e avviare il processo di approvazione dell'ordine.",
            "tenant": "Inquilino",
            "place_holder": "Indirizzo e-mail",
            "last_used_emails": "Indirizzi e-mail utilizzati di recente",
            "delete_last_used_emails": "Cancellare la voce \"Utilizzato di recente\".",
            "has_no_account": "Non ha ancora un account?",
            "login_link_via_email": "Riceva il link di accesso via e-mail",
            "finish_login_title": "Accesso completo",
            "finish_login_instructions": "Riceverà ora un'e-mail con un link di accesso. Clicchi sul link per completare il login. <br><br>L'e-mail di accesso è stata inviata a <br><b>{email}</b><br> e dovrebbe arrivare a breve. Se necessario, controlli la cartella spam.",
            "forgot_passwort": {
                "title": "Password dimenticata",
                "login_via_email": "Effettui l'accesso via e-mail",
                "instructions": "Non ci sono password in liftbase. Le inviamo un'e-mail con un link di accesso. Oppure può utilizzare i passkeys."
            },
            "no_email": "Nell'e-mail",
            "no_email_validation": "Inserisca il suo indirizzo e-mail per accedere.",
            "check_email_title": "Controlla l'e-mail",
            "check_email_message": "Controlli ora la sua casella di posta elettronica e clicchi sul link di accesso."
        },
        "or": "o",
        "passkey": {
            "title": "Effettui il login con la chiave d'accesso",
            "instructions": "Clicchi sul pulsante per accedere tramite passkey.",
            "login": "Effettui il login con la chiave d'accesso",
            "what_is": {
                "title": "Cosa sono i passepartout?",
                "first": "Effettui il login senza attendere l'e-mail",
                "second": "Con la sua impronta digitale, il suo volto o il PIN",
                "third": "Si configuri ora in pochi passaggi nel suo profilo"
            },
            "error": "Errore nell'accesso con la chiave d'accesso. La preghiamo di riprovare."
        }
    },
    "languages": {
        "de": "Tedesco",
        "en": "Inglese",
        "it": "Italian",
        "cz": "Repubblica Ceca",
        "sk": "Slovacco"
    },
    "translation-component": {
        "repeated": "Non puoi selezionare la stessa lingua più volte.",
        "language_empty": "Devi selezionare la lingua.",
        "translation_empty": "Devi inserire il testo per questa lingua selezionata.",
        "both_empty": "Devi inserire sia la lingua che il testo.",
        "language": "Lingua",
        "no_translation": "Non c'è testo registrato per questa lingua.",
        "at_least_one_required": "Devi fornire almeno un nome.",
        "text_without_translation": "Senza traduzione"
    },
    "mailbox": {
        "description": "Cassetta della posta",
        "description_plural": "Caselle postali",
        "name": "Nome",
        "username": "Nome utente",
        "password": "Password",
        "host": "Ospite",
        "protocol": "Protocollo",
        "folder": "cartella",
        "mailbox": "MailBox",
        "period_in_days": "Periodo in giorni",
        "period_in_days_column": "Periodo (giorni)",
        "period_in_days_tooltip": "Età delle e-mail in giorni",
        "remove_mailbox_msg": "È sicuro di voler rimuovere questa casella postale?",
        "remove_mailbox_btn": "Rimuovere",
        "import_invoices_automatically": "Importazione delle fatture attiva",
        "import_mode": "Modalità di importazione",
        "import_mode_per_attachment": "Creare una fattura per ogni allegato email",
        "import_mode_per_email": "Creare una fattura per ogni email",
        "check_connection_btn": "Controllare la connessione",
        "connectivity_success_msg": "Connessione stabilita con successo.",
        "connectivity_error_msg": "Non è stato possibile stabilire una connessione. Controlli le sue credenziali.",
        "save_and_connect_btn": "Salvare e connettersi",
        "disconnect_btn": "Disconnettersi",
        "disconnect_success_msg": "La connessione si è scollegata con successo."
    },
    "marketplace": {
        "description": "Marketplace",
        "description_plural": "Marketplace",
        "provider": "Fornitore",
        "country_code": "Paese",
        "url": "URL",
        "email": "Email",
        "order_url": "URL dell'ordine",
        "identity": "Identità",
        "secret": "Segreto",
        "new": "Nuovo Marketplace"
    },
    "payment_options": {
        "label": "Metodo di pagamento dell'azienda fornitrice",
        "AUTOMATIC_DEBIT": "Tramite addebito SEPA",
        "CREDIT_CARD": "Tramite carta di credito",
        "BANK_TRANSFER": "Tramite bonifico bancario"
    }

}